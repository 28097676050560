import googleLibphonenumber from 'google-libphonenumber';

import { isString } from './isString';

const phoneUtil = googleLibphonenumber.PhoneNumberUtil.getInstance();

/**
 * 檢查給定地區的電話號碼是否有效。
 *
 * @param phone - 電話號碼。
 * @param region - 地區代碼。
 */
export function isValidPhoneNumber(phone: string, region?: string): boolean {
  if (!isValidFormat(phone)) return false;

  // 如果電話號碼不含國碼（開頭沒有 + 號），則驗證地區代碼是否有傳跟有效
  if (!phone.startsWith('+')) {
    if (!isString(region) || !isValidRegion(region)) return false;
  }

  try {
    const number = phoneUtil.parseAndKeepRawInput(phone, region);
    return phoneUtil.isValidNumber(number);
  } catch (error) {
    return false;
  }
}

function isValidFormat(phone: string): boolean {
  const pattern = /[A-Za-z]+/;
  return !(phone.length <= 1 || phone.length >= 19 || pattern.test(phone));
}

function isValidRegion(region: string): boolean {
  const regionPattern = /^[A-Z]{2}$/;
  return regionPattern.test(region);
}
