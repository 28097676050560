import API from '@/api'

export const state = {
  vouchers: []
}

export const actions = {
  async getVouchers ({ commit }, { code }) {
    const result = await API.getVouchers({ code })
    commit('setVoucherList', result.status === 'SUCCESS' ? result.result : [])
    return result
  },
  async getVoucherById (_, { code, voucherId }) {
    const result = await API.getVoucherById({ code, voucherId })
    return result
  },
  async receiveVoucherById (_, { code, data }) {
    const result = await API.receiveVoucherById({ code, data })
    return result
  },
  async removeVoucherById (_, { code, data }) {
    const result = await API.removeVoucherById({ code, data })
    return result
  }
}

export const mutations = {
  setVoucherList (state, data) {
    state.vouchers = data
  }
}

export const getters = {
  vouchers: state => state.vouchers
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
