import axios from 'axios'
import { get } from 'lodash-es'
import { UAParser } from 'ua-parser-js'

import { getLocalStorage, getWebVersion, removeLocalStorage, sendDatadogLog, setLocalStorage } from '@/utils'
import { phoneNumberFormat } from '@/utils/phoneNumberFormat'
import { renderUnixTimeFormat } from '@/utils/timeFormat'


const parser = new UAParser()
const browserResult = parser.getResult()

export default {
  async login ({ accountField = 'lineUserId', lineToken = '', code = '', userId = '' }) {
    return sendAPI({
      method: 'post',
      uri: `/auth/login?code=${code}`,
      data: { accountField, lineToken, userId },
      useAuth: false
    })
  },
  async register ({ userInfo, code }) {
    return sendAPI({
      method: 'post',
      uri: `/auth/register?code=${code}`,
      data: userInfo,
      useAuth: false
    })
  },
  async sendValidateCode ({ phoneNumber, regionCode = 'TW', code, sendType = 'sms', smsSupplier = 'paasoo' }) {
    const phoneE164 = phoneNumberFormat({
      phone: phoneNumber,
      region: regionCode,
      format: 'E164'
    })

    return sendAPI({
      method: 'post',
      uri: `/auth/sendValidateCode?code=${code}`,
      data: { phoneE164, sendType, smsSupplier },
      useAuth: false
    })
  },
  async validatePhoneNumber ({ phoneNumber, regionCode = 'TW', code = '' }) {
    const phoneE164 = phoneNumberFormat({
      phone: phoneNumber,
      region: regionCode,
      format: 'E164'
    })

    return sendAPI({
      method: 'post',
      uri: '/auth/validatePhoneNumber',
      data: { phoneE164, code },
      useAuth: false
    })
  },
  async getStore ({ code = '', userId = '' }) {
    return sendAPI({
      uri: userId ? `/bookings/store?code=${code}&userId=${userId}` : `/bookings/store?code=${code}`,
      useAuth: true
    })
  },
  async getServiceMenu ({ code = '', userId = '', customerId = '' }) {
    return sendAPI({
      uri: userId ? `/bookings/menu/service?code=${code}&userId=${userId}&customerId=${customerId}` : `/bookings/menu/service?code=${code}&customerId=${customerId}`,
      useAuth: true
    })
  },
  async getRetailMenu ({ code = '', userId = '' }) {
    return sendAPI({
      uri: userId ? `/bookings/menu/retail?code=${code}&userId=${userId}` : `/bookings/menu/retail?code=${code}`,
      useAuth: true
    })
  },
  async getProductById ({ code = '', productId = '', userId }) {
    return sendAPI({
      uri: userId ? `/bookings/products/${productId}?code=${code}&userId=${userId}` : `/bookings/products/${productId}?code=${code}`,
      useAuth: true
    })
  },
  async getBookingTimes ({ code = '', startTime = 0, endTime = 0, events = [], parallel, userId = null, customerCount = 1 }) {
    const obj = { startTime, endTime, events, parallel, customerCount }
    if (userId) {
      Object.assign(obj, { userId })
    }
    if (renderUnixTimeFormat({ timestamp: startTime, format: 'HH:mm' }) !== '00:00') {
      sendDatadogLog({ message: '線上預約API：起始時間不對', data: obj, status: 'error' })
    }
    return sendAPI({
      method: 'post',
      uri: `/bookings/duration?code=${code}`,
      data: obj,
      useAuth: false
    })
  },
  async getCustomer ({ code = '' }) {
    return sendAPI({
      uri: `/customer?code=${code}`,
      useAuth: true
    })
  },
  async updateCustomer ({ code = '', data }) {
    return sendAPI({
      method: 'put',
      uri: `/customer?code=${code}`,
      data,
      useAuth: true
    })
  },
  async getCustomerValueCards ({ code = '' }) {
    return sendAPI({
      uri: `/customer/customerValueCards?code=${code}`,
      useAuth: true
    })
  },
  async getCustomerServiceTickets ({ code = '' }) {
    return sendAPI({
      uri: `/customer/customerServiceTickets?code=${code}`,
      useAuth: true
    })
  },
  async getCustomerPickupOrders ({ code = '' }) {
    return sendAPI({
      uri: `/bookings/pickupOrders?code=${code}`,
      useAuth: true
    })
  },
  async removeCustomerValueCard ({ code = '', data }) {
    return sendAPI({
      method: 'delete',
      uri: `/customer/customerValueCard?code=${code}`,
      data,
      useAuth: true
    })
  },
  async sendBookingInfo ({ code = '', data }) {
    return sendAPI({
      method: 'post',
      uri: `/bookings?code=${code}`,
      data,
      useAuth: true
    })
  },
  async updateBookingInfo ({ code = '', data }) {
    return sendAPI({
      method: 'put',
      uri: `/bookings?code=${code}`,
      data,
      useAuth: false
    })
  },
  async removeTempBookingInfo ({ code = '', data }) {
    return sendAPI({
      method: 'delete',
      uri: `/bookings?code=${code}`,
      data,
      useAuth: false
    })
  },
  async checkout ({ code = '', data }) {
    return sendAPI({
      method: 'post',
      uri: `/bookings/checkout?code=${code}`,
      data,
      useAuth: true
    })
  },
  async checkItems ({ code = '', data }) {
    return sendAPI({
      method: 'post',
      uri: `/bookings/checkItems?code=${code}`,
      data,
      useAuth: true
    })
  },
  async getBookings ({ code = '' }) {
    return sendAPI({
      uri: `/bookings?code=${code}`,
      useAuth: true
    })
  },
  async getBookingById ({ code = '', bookingId }) {
    return sendAPI({
      uri: `/bookings/${bookingId}?code=${code}`,
      useAuth: false
    })
  },
  async getPickupOrderById ({ code = '', pickId = '' }) {
    return sendAPI({
      uri: `/bookings/pickupOrders/${pickId}?code=${code}`,
      useAuth: false
    })
  },
  async getAvailablePickupTime ({ code = '', startTime = 0, endTime = 0 }) {
    return sendAPI({
      uri: `/bookings/availablePickupTime?code=${code}&startTime=${startTime}&endTime=${endTime}`,
      useAuth: false
    })
  },
  async getVouchers ({ code = '' }) {
    return sendAPI({
      uri: `/bookings/vouchers?code=${code}`,
      useAuth: true
    })
  },
  async getVoucherById ({ code = '', voucherId = '' }) {
    return sendAPI({
      uri: `/bookings/vouchers/${voucherId}?code=${code}`,
      useAuth: true
    })
  },
  async receiveVoucherById ({ code = '', data }) {
    return sendAPI({
      method: 'post',
      uri: `/bookings/vouchers?code=${code}`,
      data,
      useAuth: true
    })
  },
  async removeVoucherById ({ code = '', data }) {
    return sendAPI({
      method: 'delete',
      uri: `/bookings/vouchers?code=${code}`,
      data,
      useAuth: true
    })
  },
  async createPickupOrder ({ code = '', data }) {
    return sendAPI({
      method: 'post',
      uri: `/bookings/pickupOrders?code=${code}`,
      data,
      useAuth: true
    })
  },
  async updatePickupOrder ({ code = '', data }) {
    return sendAPI({
      method: 'put',
      uri: `/bookings/pickupOrders?code=${code}`,
      data,
      useAuth: true
    })
  },
  async getFormById ({ code = '', formId = '' }) {
    return sendAPI({
      uri: `/form/${formId}?code=${code}`,
      useAuth: false
    })
  },
  async getViewFormById ({ code = '', formId = '' }) {
    return sendAPI({
      uri: `/form/view/${formId}?code=${code}`,
      useAuth: true
    })
  },
  async updateFormById ({ code = '', data }) {
    return sendAPI({
      method: 'put',
      uri: `/form?code=${code}`,
      data,
      useAuth: true
    })
  },
  async uploadSignatureImage ({ code = '', data }) {
    return sendAPI({
      method: 'post',
      uri: `/form/signatureUpload?code=${code}`,
      data,
      useAuth: true
    })
  }
}

async function sendAPI ({ method = 'get', uri, data, customHeaders, useAuth = true, download = false }) {
  const headers = genHeaders(useAuth)
  if (customHeaders) {
    Object.assign(headers, customHeaders)
  }
  const options = {
    url: import.meta.env.VITE_APP_API + uri,
    method,
    data,
    headers
  }
  if (download) {
    options.responseType = 'blob'
  }
  const resp = await axios(options)
  if (resp.status === 401) {
    removeLocalStorage('User')
    window.location.reload()
  } else if (resp.status !== 200) {
    const storeLocalStorage = getLocalStorage('Store')
    sendDatadogLog({ message: `商店名稱：【${get(storeLocalStorage, 'name', '未知商店')}】，API response 錯誤 status：${resp.status}，錯誤內容：${resp.statusText}`, data: { 回應資料: resp.data, headers: resp.headers }, status: 'error' })
  }
  // NOTE: 取得最新版號，與目前網頁版號
  setLocalStorage('Version', { webVersion: resp.headers['x-web-version'], nowVersion: import.meta.env.VITE_APP_VERSION })
  getWebVersion()
  return resp.data
}

function genHeaders (useAuth) {
  const headers = {
    'x-app-device': browserResult.browser.name,
    'x-app-version': import.meta.env.VITE_APP_VERSION,
    'x-app-client-os': browserResult.os.name,
    'x-app-client-version': browserResult.os.version,
    'x-app-browser': browserResult.browser.name,
    'x-app-browser-version': browserResult.browser.version
  }
  if (!useAuth) {
    return headers
  }
  const userData = getLocalStorage('User')
  headers['x-access-token'] = userData ? userData.token : null
  return headers
}
