import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'

import { isFunction } from './isFunction'
import { isNumber } from './isNumber'
import { isValidPhoneNumber } from './isValidPhoneNumber'

export type PhoneNumberFormatOptions = {
  /**
   * 電話號碼
   */
  phone: string,

  /**
   * 地區代碼
   * @default 'TW'
   */
  region?: string

  /**
   * 電話號碼格式，或是提供一個函式，接收 `PhoneNumber` 並回傳格式化後的電話號碼。
   *
   * @default PhoneNumberFormat.NATIONAL
   */
  format?: PhoneNumberFormat | keyof typeof PhoneNumberFormat | ((phoneNumber: PhoneNumber) => string)
}

export const phoneUtil = PhoneNumberUtil.getInstance()

/**
 * 根據提供的選項格式化電話號碼。
 * @param options - 用於格式化電話號碼的選項。
 * @returns 格式化後的電話號碼。
 */
export function phoneNumberFormat({
  phone,
  region,
  format = PhoneNumberFormat.NATIONAL
}: PhoneNumberFormatOptions) {

  if (!phone) return ''

  const isValid = isValidPhoneNumber(phone, region)
  if (!isValid) return ''

  try {
    const number = phoneUtil.parseAndKeepRawInput(phone, region)
    if (isFunction(format)) return format(number)

    format = isNumber(format) ? format : PhoneNumberFormat[format]
    return phoneUtil.format(number, format)
  } catch (error) {
    return ''
  }
}

