import API from '@/api'

export const state = {
  user: {
    lastName: '',
    gender: '',
    phone: '',
    email: '',
    avatar: '',
    birth: {
      year: '',
      month: '',
      date: ''
    },
    appointmentTimes: 0,
    totalAmount: 0
  },
  valueCard: [],
  serviceTickets: []
}

export const actions = {
  async getCustomerInfo ({ commit }, { code }) {
    const result = await API.getCustomer({ code })
    commit('getCustomerInfo', result.result)
    return result
  },
  async updateCustomerInfo (_, { code, data }) {
    const result = await API.updateCustomer({ code, data })
    return result
  },
  async getCustomerValueCards ({ commit }, { code }) {
    const result = await API.getCustomerValueCards({ code })
    commit('getCustomerValueCards', result.result)
    return result
  },
  async removeCustomerValueCard (_, { code, data }) {
    const result = await API.removeCustomerValueCard({ code, data })
    return result
  },
  async getCustomerServiceTickets ({ commit }, { code, data }) {
    const result = await API.getCustomerServiceTickets({ code, data })
    commit('setCustomerServiceTickets', result.result)
    return result
  },
  async getCustomerPickupOrders (_, { code }) {
    const result = await API.getCustomerPickupOrders({ code })
    return result
  }
}

export const mutations = {
  getCustomerInfo (state, data) {
    state.user = data
  },
  getCustomerValueCards (state, data) {
    state.valueCard = data
  },
  setCustomerServiceTickets (state, data) {
    state.serviceTickets = data
  }
}

export const getters = {
  user: state => state.user,
  valueCard: state => state.valueCard,
  serviceTickets: state => state.serviceTickets
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
