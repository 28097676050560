import { datadogLogs } from '@datadog/browser-logs'
import DOMPurify from 'dompurify'
import { get, isArray, max, maxBy, min, minBy, uniq } from 'lodash-es'
import mixpanel from 'mixpanel-browser'

import { getDateTimeByText, getDateTimeByUnixTime, getNow, getTimeZoneAndLocale, getWeekdayNameList, renderUnixTimeFormat } from '@/utils/timeFormat.js'

function getLocalStorage (name) {
  const data = localStorage.getItem(`QLiEERBeautyBooking-${name}`)
  return (data !== null) ? JSON.parse(data) : null
}

function setLocalStorage (name, newObj) {
  const getData = localStorage.getItem(`QLiEERBeautyBooking-${name}`)
  const obj = (getData !== null) ? JSON.parse(getData) : {}
  const keys = Object.keys(newObj)
  keys.forEach(key => {
    const value = newObj[key]
    if (value !== undefined) {
      obj[key] = value
    }
  })
  localStorage.setItem(`QLiEERBeautyBooking-${name}`, JSON.stringify(obj))
}

function removeLocalStorage (name) {
  const data = localStorage.getItem(`QLiEERBeautyBooking-${name}`)
  if (data) {
    localStorage.removeItem(`QLiEERBeautyBooking-${name}`)
  }
}

function addDatadogGlobalContext ({ key = '', value = '' }) {
  datadogLogs.addLoggerGlobalContext(key, value)
}

function sendDatadogLog ({ message = '', data = {}, status = 'info' }) {
  datadogLogs.logger.log(message, data, status)
}

function formatThousandth (number) {
  number += ''
  const arr = number.split('.')
  const re = /(\d{1,3})(?=(\d{3})+$)/g
  return arr[0].replace(re, '$1,') + (arr.length === 2 ? '.' + arr[1] : '')
}

function formatCurrency (number) {
  return number >= 0 ? `$${formatThousandth(number)}` : `-$${formatThousandth(Math.abs(number))}`
}

function formatDiscountText ({ type = 'Percent', value = 0 }) {
  return (type === 'Percent') ? Math.round(value * 100) / 10 : Math.abs(value)
}

function formatRangeByKey ({ key = 'price', product = null, userId = null, subItemId = null, onlyShowLowest = false, onlyReturnObject = false }) {
  // NOTE: 僅適用 price / duration
  // subItemId: 純顯示服務人員專屬 price / duration
  // onlyShowLowest: 是否只顯示最低價起
  // onlyReturnObject: 是否回傳 value object
  const type = userId !== null ? 'provider' : 'product'
  const subItemType = get(product, 'subItemType', 'Single')
  const defaultValue = (key === 'price') ? null : 0
  const productDefaultValue = get(product, key, defaultValue) !== null ? get(product, key, defaultValue) : '-'
  const subItems = get(product, 'subItems', [])
  const subItemValues = subItems.map(i => i[key])
  const subItemProviders = get(product, 'subItemProviders', [])
  if (type === 'product') {
    // 顯示產品
    if (subItemType === 'Single' || (subItemType !== 'Single' && subItems.length === 0)) {
      // 無子項目
      if (onlyReturnObject) {
        return (productDefaultValue !== '-') ? { minValue: productDefaultValue, maxValue: productDefaultValue } : { minValue: 0, maxValue: 0 }
      } else {
        return (key === 'price')
          ? (productDefaultValue !== '-') ? formatCurrency(productDefaultValue) : '-'
          : formatDurationText(productDefaultValue)
      }
    } else {
      const minValue = min(subItemValues)
      const maxValue = max(subItemValues)
      // 有子項目
      if (subItemId) {
        const targetValueArr = subItemProviders.reduce((arr, provider) => {
          if (provider.subItemId === subItemId) {
            arr.push({ ...provider })
          }
          return arr
        }, [])
        const targetSubItem = subItems.find(item => item.id === subItemId)
        if (targetSubItem) {
          targetValueArr.unshift({ ...targetSubItem })
        }
        if (targetValueArr.length > 0) {
          const minValueByProvider = minBy(targetValueArr, key)
          const maxValueByProvider = maxBy(targetValueArr, key)
          return onlyReturnObject ? { minValue: minValueByProvider[key], maxValue: maxValueByProvider[key] } : renderRangeText({ key, minValue: minValueByProvider[key], maxValue: maxValueByProvider[key], onlyShowLowest })
        } else {
          return onlyReturnObject ? { minValue, maxValue } : renderRangeText({ key, minValue, maxValue, onlyShowLowest })
        }
      } else {
        return onlyReturnObject ? { minValue, maxValue } : renderRangeText({ key, minValue, maxValue, onlyShowLowest })
      }
    }
  } else if (type === 'provider') {
    // 顯示 User
    if (subItemType === 'Single' || (subItemType !== 'Single' && subItems.length === 0)) {
      // 無子項目
      const providers = get(product, 'providers', [])
      const provider = providers.find(provider => provider.userId === userId)
      const isDefault = get(provider, 'useDefault', true)
      const providerValue = isDefault ? productDefaultValue : get(provider, key, 0)
      if (onlyReturnObject) {
        return (providerValue !== '-') ? { minValue: providerValue, maxValue: providerValue } : { minValue: 0, maxValue: 0 }
      } else {
        return (key === 'price')
          ? (providerValue !== '-') ? formatCurrency(providerValue) : '-'
          : formatDurationText(providerValue)
      }
    } else {
      // 有子項目
      const subItemValuesByUser = subItems.reduce((arr, item) => {
        const subItemProvider = subItemProviders.find(provider => provider.userId === userId && provider.subItemId === item.id)
        const { id: subItemId, name } = item
        arr.push({
          name,
          subItemId,
          duration: subItemProvider ? subItemProvider.duration : item.duration,
          price: subItemProvider ? subItemProvider.price : item.price,
          userId: subItemProvider ? subItemProvider.userId : null
        })
        return arr
      }, [])
      if (subItemId) {
        // 純顯示服務人員子項目
        const targetItem = subItemValuesByUser.find(o => o.subItemId === subItemId)
        if (targetItem) {
          const targetValue = targetItem[key]
          return onlyReturnObject ? { minValue: targetValue, maxValue: targetValue } : renderRangeText({ key, minValue: targetValue, maxValue: targetValue, onlyShowLowest })
        } else {
          const minValue = min(subItemValues)
          const maxValue = max(subItemValues)
          return onlyReturnObject ? { minValue, maxValue } : renderRangeText({ key, minValue, maxValue, onlyShowLowest })
        }
      } else {
        const minValue = (subItemValuesByUser.length > 0) ? minBy(subItemValuesByUser, key) : minBy(subItemValues, key)
        const maxValue = (subItemValuesByUser.length > 0) ? maxBy(subItemValuesByUser, key) : maxBy(subItemValues, key)
        return onlyReturnObject ? { minValue: minValue[key], maxValue: maxValue[key] } : renderRangeText({ key, minValue: minValue[key], maxValue: maxValue[key], onlyShowLowest })
      }
    }
  }
}

function formatWeekdayNames (value) {
  const weekdayNames = getWeekdayNameList({ type: 'short', optionLocale: 'zh-TW' })
  return weekdayNames[value]
}

function renderUserProtectionText () {
  // NOTE: 保護公司與集團的前提下，法務建議需在消費者看得到儲值金或接觸金流頁面都呈現。若未來文案需要動態顯示不同內容，在透過此變數去各自頁面修改
  return '客立樂僅為系統平台，提供服務予店家。客立樂非屬本交易之一方，任何交易或產品之內容或需求，消費者應與店家聯繫確認。'
}

function renderRangeText ({ key = 'price', minValue = 0, maxValue = 0, onlyShowLowest = false }) {
  if (key === 'price') {
    return (minValue === maxValue) ? `${formatCurrency(minValue)}` : `${onlyShowLowest ? formatCurrency(minValue) + ' 起' : formatCurrency(minValue) + ' ~ ' + formatCurrency(maxValue)}`
  } else if (key === 'duration') {
    return (minValue === maxValue) ? `${formatDurationText(minValue)}` : `${onlyShowLowest ? formatDurationText(minValue) + ' 起' : formatDurationText(minValue) + ' ~ ' + formatDurationText(maxValue)}`
  }
}

function renderOrderInfoTime ({ time = null, type = 'origin', dateType = '', value = 0 }) {
  // NOTE: firstRefund 為算出第一個 可退款/免費條件，天/小時都必須往前推一個單位
  const dt = getDateTimeByUnixTime({ unixTime: time })
  const minusObj = {}
  if (dateType) {
    if (type === 'firstRefund') {
      minusObj[dateType] = Number(value) + 1
      dt.minus(minusObj).endOf(dateType)
    } else {
      minusObj[dateType] = Number(value)
      dt.minus(minusObj)
    }
  }
  return dt.toFormat('L/d, HH:mm')
}

function formatRefundMainKey ({ deadline, refundType, refundPercent, refundAmount }) {
  const dateArr = ['days', 'hours']
  const deadlineType = deadline.split('_')
  return {
    deadlineType,
    dateTypeKey: dateArr.indexOf(deadlineType[0].toLowerCase()) === 0 ? 'day' : 'hour',
    refundClass: `${refundPercent === 100 ? 'text-success' : 'text-warning'}`,
    refundText: `${refundPercent === 100 ? '無條件全額退款' : `退款 ${(refundType === 'Percent') ? refundPercent + '% 已付金額' : formatCurrency(refundAmount)}`}`,
    refundDate: `${deadlineType[1]} ${deadlineType[0] === 'DAYS' ? '天' : '小時'}`
  }
}

function formatVersion ({ webVersion, nowVersion }) {
  const webVersionDate = webVersion ? webVersion.split('(') : null
  const nowVersionDate = nowVersion ? nowVersion.split('(') : null
  return webVersionDate && nowVersionDate ? { webDate: webVersionDate[1].replace(')', ''), nowDate: nowVersionDate[1].replace(')', '') } : { webDate: null, nowDate: null }
}

function getRefundAmountListFromAPI ({ storeInfo = {}, orderInfo = {}, type = 'origin', refundAmountList: apiRefundAmountList = [] }) {
  // NOTE: 採用 API 退款資訊顯示文案
  let cancelPolicy = get(storeInfo, 'cancelPolicy', 'Multiple')
  let depositRequired = get(storeInfo, 'deposit.required', false)
  const depositRule = get(orderInfo, 'depositRule', null)
  const depositScope = get(orderInfo, 'depositScope', 'All')
  const depositName = (depositScope === 'Partial') ? '訂金' : '退款'
  if (depositRule === 'Allowed') {
    // NOTE: 非訂金對象，可進行全額退款 (類似於單一條件)
    cancelPolicy = 'Single'
    depositRequired = false
  } else if (depositRule === 'Restricted') {
    depositRequired = true
  }
  if (type === 'firstRefund') {
    if (cancelPolicy === 'None') {
      return `<span>${depositRequired ? `預約後不得取消且不返還${depositName}` : '預約後不得取消且不退款'}</span>`
    }
    // 預設不退款文案
    let str = `<span>${depositRequired ? `預約後若取消將不返還${depositName}` : '預約後不得取消且不退款'}</span>`
    const apiResultTimeIndex = apiRefundAmountList.findIndex(listItem => listItem.isCurrent)
    if (apiResultTimeIndex > -1) {
      const { endTime, refundType, refundPercent, refundAmount } = apiRefundAmountList[apiResultTimeIndex]
      const targetDateText = renderUnixTimeFormat({ timestamp: endTime, format: 'L/d, HH:mm' })
      const refundDescription = (refundType === 'Percent') ? `${refundPercent}%` : `${formatCurrency(refundAmount)}`
      if (refundPercent === 100) {
        str = `<span>${targetDateText} 前可免費取消預約</span>`
      } else if (refundType === 'Percent' ? (refundPercent > 0 && refundPercent < 100) : refundAmount > 0) {
        str = `<span>${targetDateText} 前取消可返還 ${refundDescription} ${depositName}</span>`
      }
    }
    return str
  } else {
    const textArr = []
    const hasRefundPercentList = apiRefundAmountList.filter(l => l.refundPercent !== 0 || l.refundAmount !== 0)
    if (hasRefundPercentList.length === 0) {
      // 不得取消只有不退款條件，且 apiRefundAmountList 為空值
      if (cancelPolicy === 'None' || apiRefundAmountList.length === 0) {
        textArr.push((type === 'detail') ? '<div>顧客預約後不得取消且不退款。</div>' : '')
        return textArr.join('')
      }
      // 只有不退款條件
      const { deadline, refundType, refundPercent, refundAmount } = apiRefundAmountList[0]
      const { deadlineType, dateTypeKey, refundClass, refundText, refundDate } = formatRefundMainKey({ deadline, refundType, refundPercent, refundAmount })
      if (refundPercent !== 0 || refundAmount !== 0) {
        const targetRefundItem = apiRefundAmountList.find(listItem => listItem.deadline === deadline)
        const deadlineTimeValue = targetRefundItem ? renderUnixTimeFormat({ timestamp: targetRefundItem.endTime, format: 'L/d, HH:mm' }) : renderOrderInfoTime({ time: orderInfo.date, type: 'firstRefund', dateType: dateTypeKey, value: deadlineType[1] })
        const refundDescription = (refundType === 'Percent') ? `${refundPercent}% 已付金額` : `${formatCurrency(refundAmount)}`
        textArr.push((type === 'detail')
          ? `<div>顧客於到店日 <span class="${refundClass}">${refundDate}前</span> 取消預約，店家將退款 <span class="${refundClass}">${refundDescription}</span>。</div>`
          : `<div class="timeline-${refundClass}"><div>到店日<span class="${refundClass}"> ${refundDate}前</span></div><div class="${refundClass}"> ${refundText}</div><div class="time">${deadlineTimeValue}</div></div>`)
      }

      const targetRefundDetailText = (refundPercent === 0 && refundAmount === 0) ? '<div>顧客於到店日前取消預約或當日未到店，店家將不退款。' : `<div>顧客於到店日 <span class="text-red">${refundDate}內</span> 取消預約或當日未到店，店家將不退款。`
      textArr.push((type === 'detail')
        ? targetRefundDetailText
        : `<div class="timeline-text-red"><div>到店日</div><div class="text-red">不提供退款</div><div class="time">${renderOrderInfoTime({ time: orderInfo.date })}</div></div>`)
    } else {
      switch (cancelPolicy) {
        // NOTE: 這邊不會有不得取消的判斷，因為取消按鈕會消失
        case 'Single':
          // NOTE: 一般取消
          for (let i = 0; i < apiRefundAmountList.length; i++) {
            const condition = apiRefundAmountList[i]
            const { deadline, refundType, refundPercent, refundAmount } = condition
            const { deadlineType, dateTypeKey, refundClass, refundText, refundDate } = formatRefundMainKey({ deadline, refundType, refundPercent, refundAmount })
            const refundDescription = (refundType === 'Percent') ? `${refundPercent}% 已付金額` : `${formatCurrency(refundAmount)}`
            if (deadline === 'UNLIMITED') {
              // NOTE: 一般取消(不限制)
              const defaultDeadlineDesc = (depositRule === 'None') ? '<div>顧客於到店日前可免費取消預約。</div>' : '<div>顧客於到店日前取消預約，店家將退款 <span class="text-success"> 100% </span> 已付金額。</div>'
              textArr.push((type === 'detail') ? defaultDeadlineDesc : '')
              return textArr.join('')
            }
            // NOTE: 一般取消(允許退款)
            if (refundPercent !== 0 || refundAmount !== 0) {
              const targetRefundItem = apiRefundAmountList.find(listItem => listItem.deadline === deadline)
              // NOTE: 一般取消只有長度為 2 的退款列表
              const prevCondition = apiRefundAmountList[0]
              const { refundDate: prevRefundDate } = formatRefundMainKey({ deadline: prevCondition.deadline, refundPercent: prevCondition.refundPercent })
              const deadlineTimeValue = targetRefundItem ? renderUnixTimeFormat({ timestamp: targetRefundItem.endTime, format: 'L/d, HH:mm' }) : renderOrderInfoTime({ time: orderInfo.date, type: (i === 0) ? 'firstRefund' : 'origin', dateType: dateTypeKey, value: deadlineType[1] })
              const defaultDeadlineDesc = (depositRule === 'None') ? `<div>顧客於到店日 <span class="${refundClass}">${deadline === 'END_TIME' ? prevRefundDate + '內' : refundDate + '前'}</span> 可以免費取消預約。</div>` : `<div>顧客於到店日 <span class="${refundClass}">${deadline === 'END_TIME' ? prevRefundDate + '內' : refundDate + '前'}</span> 取消預約，店家將退款 <span class="${refundClass}">${refundDescription}</span>。</div>`
              textArr.push((type === 'detail')
                // NOTE: 透過 END_TIME 確認退款文案
                // END_TIME 表示退款時間「內」，否則都是退款時間「前」
                ? defaultDeadlineDesc
                : `<div class="timeline-${refundClass}"><div>到店日<span class="${refundClass}">${deadline === 'END_TIME' ? prevRefundDate + '內' : refundDate + '前'}</span></div><div class="${refundClass}"> ${refundText}</div><div class="time">${deadlineTimeValue}</div></div>`)
            } else {
              // NOTE: 一般取消(不允許退款)
              if (apiRefundAmountList.length - 1 === i && i > 0) {
                // NOTE: 找到最後一個 refundPercent !== 0 && refundAmount !== 0 的條件並顯示
                const mappingLists = apiRefundAmountList.filter(l => l.refundPercent > 0 || l.refundAmount > 0)
                const prevCondition = apiRefundAmountList[mappingLists.length - 1]
                const { refundDate: preRefundType } = formatRefundMainKey({ deadline: prevCondition.deadline, refundPercent: prevCondition.refundPercent })
                const targetRefundDetailText = `<div>顧客於到店日 <span class="text-red">${(refundPercent === 0 && refundAmount === 0) ? preRefundType : refundDate}內</span> 取消預約或當日未到店，店家將不退款。`
                const defaultDeadlineDesc = (depositRule === 'None') ? '' : targetRefundDetailText
                textArr.push((type === 'detail')
                  ? defaultDeadlineDesc
                  : `<div class="timeline-text-red"><div>到店日</div><div class="text-red">不提供退款</div><div class="time">${renderOrderInfoTime({ time: orderInfo.date })}</div></div>`)
              }
            }
          }
          break
        case 'Multiple':
          // NOTE: 階梯式退款，最後都會產生不退款的條件
          for (let i = 0; i < apiRefundAmountList.length; i++) {
            const condition = apiRefundAmountList[i]
            const { deadline, refundType, refundPercent, refundAmount } = condition
            const { deadlineType, dateTypeKey, refundClass, refundText, refundDate } = formatRefundMainKey({ deadline, refundType, refundPercent, refundAmount })
            const refundDescription = (refundType === 'Percent') ? `${refundPercent}% 已付金額` : `${formatCurrency(refundAmount)}`
            if (refundPercent !== 0 || refundAmount !== 0) {
              const targetRefundItem = apiRefundAmountList.find(listItem => listItem.deadline === deadline)
              const deadlineTimeValue = targetRefundItem ? renderUnixTimeFormat({ timestamp: targetRefundItem.endTime, format: 'L/d, HH:mm' }) : renderOrderInfoTime({ time: orderInfo.date, type: (i === 0) ? 'firstRefund' : 'origin', dateType: dateTypeKey, value: deadlineType[1] })
              textArr.push((type === 'detail')
                ? `<div>顧客於到店日 <span class="${refundClass}">${refundDate}前</span> 取消預約，店家將退款 <span class="${refundClass}">${refundDescription}</span>。</div>`
                : `<div class="timeline-${refundClass}"><div>到店日<span class="${refundClass}"> ${refundDate}前</span></div><div class="${refundClass}"> ${refundText}</div><div class="time">${deadlineTimeValue}</div></div>`)
            }
            // NOTE: 後端會多產生 END_TIME 結構，故只有一個不退款條件時，會是產生長度為2以上的退款列表
            if (apiRefundAmountList.length - 1 === i && i > 0) {
              // NOTE: 找到最後一個 refundPercent !== 0 && refundAmount !== 0 的條件並顯示
              const mappingLists = apiRefundAmountList.filter(l => l.refundPercent > 0 || l.refundAmount > 0)
              const prevCondition = apiRefundAmountList[mappingLists.length - 1]
              const { refundDate: preRefundType } = formatRefundMainKey({ deadline: prevCondition.deadline, refundPercent: prevCondition.refundPercent })
              const targetRefundDetailText = `<div>顧客於到店日 <span class="text-red">${(refundPercent === 0 && refundAmount === 0) ? preRefundType : refundDate}內</span> 取消預約或當日未到店，店家將不退款。`
              textArr.push((type === 'detail')
                ? targetRefundDetailText
                : `<div class="timeline-text-red"><div>到店日</div><div class="text-red">不提供退款</div><div class="time">${renderOrderInfoTime({ time: orderInfo.date })}</div></div>`)
            }
          }
          break
      }
    }
    return textArr.join('')
  }
}

function getCancelCondition ({ storeInfo = {}, orderInfo = {}, type = 'origin' }) {
  // NOTE: 根據商店的退款資訊顯示文案
  let cancelPolicy = get(storeInfo, 'cancelPolicy', 'Multiple')
  const cancelCondition = get(storeInfo, 'cancelCondition', [])
  let depositRequired = get(storeInfo, 'deposit.required', false)
  const depositRule = get(orderInfo, 'depositRule', null)
  const depositScope = get(orderInfo, 'depositScope', 'All')
  const depositName = (depositScope === 'Partial') ? '訂金' : '退款'
  if (type === 'firstRefund' && cancelCondition.length > 0) {
    // NOTE: 預設不退款文案，純前端計算，先暫時保留，除非拉不到 refundAmountList 才使用這段計算
    let str = `<span>預約後若取消將不返還${depositName}<span>`
    let isFirst = false
    for (let i = 0; i < cancelCondition.length; i++) {
      const { deadline, refundType, refundPercent, refundAmount } = cancelCondition[i]
      const { deadlineType, dateTypeKey } = formatRefundMainKey({ deadline, refundType, refundPercent, refundAmount })
      // 非第一筆採取 type: origin
      const targetDateText = renderOrderInfoTime({ time: orderInfo.date, type: (i === 0) ? 'firstRefund' : 'origin', dateType: dateTypeKey, value: deadlineType[1] })
      const nowDT = getNow({ type: 'DateTimeObject' })
      const nowUnixTime = nowDT.set({ second: 0 }).toUnixInteger()
      const refundDescription = (refundType === 'Percent') ? `${refundPercent}%` : `${formatCurrency(refundAmount)}`
      // 需要確認最後一筆會顯示的情況，應該會有問題
      // 取得計算出來的退款最晚時間與現在時間比較，確認是否符合區間，並找到第一筆符合的結果
      // 如果沒有的話，預設不退款文案
      const { zone } = getTimeZoneAndLocale()
      const targetUnixTime = getDateTimeByText({ text: targetDateText, format: 'L/d, HH:mm' }).setZone(zone).toUnixInteger()
      if (nowUnixTime <= targetUnixTime && !isFirst && refundPercent !== 0) {
        str = (refundPercent === 100)
          ? `<span>${targetDateText} 前可免費取消預約</span>`
          : `<span>${targetDateText} 前取消可返還 ${refundDescription} ${depositName}</span>`
        isFirst = true
      }
    }
    return str
  } else {
    if (depositRule === 'Allowed') {
      // NOTE: 非訂金對象，可進行全額退款 (類似於單一條件)
      cancelPolicy = 'Single'
      depositRequired = false
    }
    const textArr = []
    switch (cancelPolicy) {
      case 'None':
        // NOTE: 不得取消
        textArr.push('顧客預約後不得取消且不退款。')
        break
      case 'Single':
        // NOTE: 一般取消
        // NOTE: 一般取消只有長度為 2 的退款列表
        for (let i = 0; i < cancelCondition.length; i++) {
          const { deadline, refundType, refundPercent, refundAmount } = cancelCondition[i]
          if (depositRequired === false) {
            // NOTE: 不需付訂金 + 一般取消(不限制)
            if (i === 0) {
              if (deadline === 'UNLIMITED') {
                textArr.push('<div>顧客於到店日前可免費取消預約。</div>')
              } else {
                const { refundClass, refundDate } = formatRefundMainKey({ deadline, refundType, refundPercent, refundAmount })
                textArr.push(`<div>顧客於到店日 <span class="${refundClass}">${refundDate}前</span> 可免費取消預約</div>`)
              }
            }
          } else {
            // NOTE: 一般取消(不限制)
            if (deadline === 'UNLIMITED') {
              textArr.push('<div>顧客於到店日前取消預約，店家將退款 <span class="text-success"> 100% </span> 已付金額。</div>')
              return textArr.join('')
            }
            const { refundClass, refundDate } = formatRefundMainKey({ deadline, refundType, refundPercent, refundAmount })
            const refundDescription = (refundType === 'Percent') ? `${refundPercent}% 已付金額` : `${formatCurrency(refundAmount)}`
            if (cancelCondition.length - 1 !== i) {
              textArr.push(`<div>顧客於到店日 <span class="${refundClass}">${refundDate}前</span> 取消預約，店家將退款 <span class="${refundClass}">${refundDescription}</span>。</div>`)
            } else {
              // 最後一筆 END_TIME 顯示
              const prevCondition = cancelCondition[i - 1]
              const { refundDate: preRefundType } = formatRefundMainKey({ deadline: prevCondition.deadline, refundPercent: prevCondition.refundPercent })
              textArr.push((refundPercent !== 0 || refundAmount !== 0)
                ? `<div>顧客於到店日 <span class="${refundClass}">${preRefundType}內</span> 取消預約，店家將退款 <span class="${refundClass}">${refundDescription}</span>。</div>` // NOTE: 一般取消(允許退款)
                : `<div>顧客於到店日 <span class="${refundClass}">${preRefundType}內</span> 取消預約或當日未到店，店家將不退款。</div>`) // NOTE: 一般取消(不允許退款)
            }
          }
        }
        break
      case 'Multiple':
        // NOTE: 階梯式退款
        if (cancelCondition.length === 1) {
          const { deadline, refundType, refundPercent, refundAmount } = cancelCondition[0]
          const { refundClass, refundDate } = formatRefundMainKey({ deadline, refundType, refundPercent, refundAmount })
          const refundDescription = (refundType === 'Percent') ? `${refundPercent}% 已付金額` : `${formatCurrency(refundAmount)}`
          if (refundPercent !== 0 || refundAmount !== 0) {
            textArr.push(`<div>顧客於到店日 <span class="${refundClass}">${refundDate}前</span> 取消預約，店家將退款 <span class="${refundClass}">${refundDescription}</span>。</div>`)
          }
          const targetRefundDetailText = (refundPercent === 0 && refundAmount === 0) ? '<div>顧客於到店日前取消預約或當日未到店，店家將不退款。' : `<div>顧客於到店日 <span class="text-red">${refundDate}內</span> 取消預約或當日未到店，店家將不退款。`
          textArr.push(targetRefundDetailText)
        } else {
          for (let i = 0; i < cancelCondition.length; i++) {
            const { deadline, refundType, refundPercent, refundAmount } = cancelCondition[i]
            const { refundClass, refundDate } = formatRefundMainKey({ deadline, refundType, refundPercent, refundAmount })
            const refundDescription = (refundType === 'Percent') ? `${refundPercent}% 已付金額` : `${formatCurrency(refundAmount)}`
            if (refundPercent !== 0 || refundAmount !== 0) {
              textArr.push(`<div>顧客於到店日 <span class="${refundClass}">${refundDate}前</span> 取消預約，店家將退款 <span class="${refundClass}">${refundDescription}</span>。</div>`)
            }
            if (cancelCondition.length - 1 === i && i > 0) {
              const mappingLists = cancelCondition.filter(o => o.refundAmount !== 0 || o.refundPercent !== 0)
              const prevCondition = mappingLists[mappingLists.length - 1]
              const { refundDate: preRefundType } = formatRefundMainKey({ deadline: prevCondition.deadline, refundPercent: prevCondition.refundPercent })
              textArr.push(`<div>顧客於到店日 <span class="text-red">${(refundPercent === 0 && refundAmount === 0) ? preRefundType : refundDate}內</span> 取消預約或當日未到店，店家將不退款。`)
            }
          }
        }
        break
    }
    return textArr.join('')
  }
}

function getProvideUserList ({ storeInfo = {}, product = {}, users = [], query = {} }) {
  /*
    NOTE: 產生可選的服務人員列表
    1. 依服務指定模式 (isAssigned) 來找服務人員
        isAssigned (true): 需指定
        isAssigned (false): 不指定，所有人皆可
    2. 依商店指定模式 (unassignedMode) 新增「不指定」人員
    3. 依 url query 參數決定是否為服務人員個人網站
    4. 如果有第一項服務則看是否能選取一樣的服務人員，若無則自動選取可預約的第一位服務人員
  */
  const { isAssigned = false, providers = [] } = product
  const { unassignedMode = 'Normal' } = storeInfo
  const designerId = get(query, 'userId', null)
  let arrangeUsers = []
  if (isAssigned) {
    // 該服務為指定，僅顯示有提供的服務人員
    arrangeUsers = users.reduce((arr, user) => {
      const { id = null } = user
      const targetProvider = providers.find(provider => provider.userId === id && provider.isProvided)
      if (targetProvider) {
        arr.push(user)
      }
      return arr
    }, [])
  } else {
    // 該服務為不指定
    arrangeUsers = users
  }
  // 放入不指定人員
  if (arrangeUsers.findIndex(user => user.id === null) === -1) {
    arrangeUsers.unshift({ id: null, name: '不指定', avatar: 'https://image.qlieer.app/icon/ic-beauty-shuffle-icon.svg' })
  }
  if (designerId) {
    arrangeUsers = arrangeUsers.filter(u => designerId ? u.id === designerId : true)
  } else if (unassignedMode === 'OnlyUnassigned') {
    // 僅不指定
    arrangeUsers = arrangeUsers.filter(u => u.id === null)
  } else if (unassignedMode === 'OnlyProvider') {
    // 僅指定人員
    arrangeUsers = arrangeUsers.filter(u => u.id !== null)
  }
  return arrangeUsers
}

function getWebVersion () {
  const versionLocalStorage = getLocalStorage('Version')
  // NOTE: 比對線上預約 C 端版號 和 API 強更版號，若需要強更請告知後端強更版號
  if (!get(versionLocalStorage, 'ttl', null)) {
    setLocalStorage('Version', { ttl: 0 })
  } else {
    // NOTE: 當 ttl 重試次數已達 3 次，API 強更版號仍大於 C 端版號，將 ttl 次數歸 0
    const { webVersion = null, nowVersion = null, ttl = 0 } = versionLocalStorage
    const versionDate = formatVersion({ webVersion, nowVersion })
    if (Number(versionDate.webDate) > Number(versionDate.nowDate)) {
      sendDatadogLog({ message: `嘗試重新整理次數已達: ${ttl} 次，API 強更版號仍大於 C 端版號` })
    }
  }
  checkWebVersion()
}

function checkWebVersion () {
  // NOTE: 比對版號差異
  const versionLocalStorage = getLocalStorage('Version')
  if (versionLocalStorage) {
    const { webVersion = null, nowVersion = null, ttl = 0 } = versionLocalStorage
    // NOTE: 嘗試最多 3 次重新整理，webVersion => API 版號，nowVersion => C 端版號
    const versionDate = formatVersion({ webVersion, nowVersion })
    addDatadogGlobalContext({ key: 'clientWebVersion', value: nowVersion })
    addDatadogGlobalContext({ key: 'clientApiVersion', value: webVersion })
    if (Number(versionDate.webDate) > Number(versionDate.nowDate) && ttl < 3) {
      sendDatadogLog({ message: 'API 版號大於目前版號，強制 Reload', data: { webVersion: versionDate.webDate, nowVersion: versionDate.nowDate, ttl } })
      setLocalStorage('Version', { ttl: versionLocalStorage.ttl + 1 })
      window.location.reload()
    }
  }
}

function checkDataDogLog ({ localKey = null, key = null, value = false, message = '', data = {}, status = 'info' }) {
  // NOTE: 防止網頁一口氣暴打log，預設 false 表示 log 尚未紀錄，記錄完畢再將值設定為true
  const logLocalStorage = getLocalStorage([localKey])
  if (!get(logLocalStorage, [key], null)) {
    // NOTE: 若該傳入 key 值不存在，才需要 setLocalStorage
    setLocalStorage(`${localKey}`, { [key]: value })
  }
  if (!get(logLocalStorage, `${key}`, false) || get(logLocalStorage, `${key}`, false) === undefined) {
    switch (key) {
      default:
        sendDatadogLog({ message, data, status })
        setLocalStorage(`${localKey}`, { [key]: true })
        break
    }
  }
}

function formatAppointmentStatus (status = 'new') {
  switch (status) {
    case 'Arrived':
      return '顧客已到店'
    case 'Completed':
    case 'Paid':
      return '完成結帳'
    case 'Cancelled':
      return '顧客取消'
    case 'Confirmed':
      return '已確認'
    case 'Expired':
    case 'isExpired':
      return '已過期'
    case 'Pending':
      return '店家待確認'
    case 'Closed':
      return '店家已取消'
    case 'NoShow':
      return '未出席'
    case 'New':
    default:
      return '新預約'
  }
}

function formatPaymentMethod (payment = {}) {
  const { method, isPrepaid = false, lastNum = '' } = payment
  switch (method) {
    case 'CreditCard':
      return { icon: 'ic-beauty-creditCard.svg', text: `${isPrepaid ? '線上刷卡預付' : '手輸信用卡'}${lastNum !== '' ? '(' + lastNum + ')' : ''}`, name: '線上刷卡' }
    case 'ATM':
      return { icon: isPrepaid ? 'ic-beauty-ATM.svg' : 'ic-beauty-bank.svg', text: `ATM${isPrepaid ? '轉帳預付' : ''}`, name: 'ATM 轉帳' }
    case 'ValueCard':
      return { icon: 'ic-beauty-valueCard.svg', text: payment.name, name: '儲值卡' }
    case 'LinePayOffline':
      return { icon: 'ic-beauty-LinePay.svg', text: 'LINE Pay 線下', name: 'LINE Pay 線下' }
    case 'LinePayOnline':
      return { icon: 'ic-beauty-LinePay.svg', text: 'LINE Pay 線上', name: 'LINE Pay 線上' }
    case 'Custom':
      return { icon: 'ic-beauty-customize.svg', text: payment.name || '其他付款', name: '其他付款' }
    case 'Cash':
    default:
      return { icon: 'ic-beauty-cash.svg', text: '現金', name: '現金' }
  }
}

function formatBookingOriginType (value) {
  const origin = ['LINE', 'Facebook', 'Google', 'Instagram', 'Other']
  const lowerCaseOrigin = origin.map(s => s.toLocaleLowerCase())
  const index = lowerCaseOrigin.indexOf(value.toLocaleLowerCase())
  return index > -1 ? origin[index] : 'LINE'
}

function formatDurationText (duration = 0) {
  const hour = Math.floor(duration / 60)
  const minute = duration - hour * 60
  return hour > 0 ? `${hour} 小時${minute > 0 ? ' ' + minute + ' 分' : ''}` : `${minute} 分`
}

function renderDurationOptions () {
  const arr = []
  for (let i = 0; i <= 10; i++) {
    let totalMin = 0
    if (i < 3) {
      for (let j = 1; j <= 12; j++) {
        totalMin = i * 60 + j * 5
        arr.push({ label: formatDurationText(totalMin), value: totalMin })
      }
    } else if (i < 7) {
      for (let n = 0; n < 2; n++) {
        totalMin = i * 60 + n * 30
        arr.push({ label: formatDurationText(totalMin), value: totalMin })
      }
    } else if (i >= 7) {
      totalMin = i * 60
      arr.push({ label: formatDurationText(totalMin), value: totalMin })
    }
  }
  return arr
}

function formatBirth (value) {
  return value.toString().length <= 1 ? value.toString().padStart(2, 0) : value
}

function sendMixpanelEvent ({ title = '', data = {} }) {
  const storeData = getLocalStorage('Store')
  const storeObj = storeData ? {
    store_name: storeData.name,
    store_id: storeData.storeId,
    store_type: storeData.type
  } : {}
  mixpanel.track(title, Object.assign(data, storeObj))
}

function b64ToUtf8 (str) {
  // NOTE: 編碼後的「+」字元，會因為 searchParams.get()，而導致被替換成 " "(空白字元) https://ithelp.ithome.com.tw/articles/10229587
  try {
    const newText = str.replace(/-| /g, '+')
      .replace(/_/g, '/')
      .replace(/%/g, '%25')
    return decodeURIComponent(
      window.atob(newText)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
  } catch (err) {
    sendDatadogLog({ message: '線上預約 query 解碼失敗 (b64ToUtf8)', data: { str } })
    return ''
  }
}

function decodeQueryURL (queryString = '') {
  return b64ToUtf8(queryString)
}

function renderVoucherDiscountText (voucher, formatType = 'origin') {
  /*
    formatType = origin，拿取 vouchers 底下結構
    formatType = productDiscounts，拿取 checkItem 底下 items 結構
  */
  const { type = 'Percent', discountType = 'Percent', originValue = 0, discountValue = 0 } = voucher
  const voucherType = (formatType === 'productDiscounts') ? type : discountType
  const voucherValue = (originValue !== null || discountValue !== null) ? (formatType === 'productDiscounts') ? originValue : discountValue : 1
  if (voucherType === 'Percent' && voucherValue === 0) {
    return '免費使用'
  } else if (voucherType === 'Percent') {
    return voucherValue ? `優惠 ${formatDiscountText({ type: voucherType, value: voucherValue })} 折` : ''
  } else {
    return `優惠 ${formatThousandth(Math.abs(voucherValue))} 元`
  }
}

function convertTextWithLink (article = '') {
  /*
    線上預約說明欄位
    參考：https://stackoverflow.com/questions/59495766/convert-text-link-to-multiple-html-format-in-javascript-with-xss-filter
    未來如果有需要 @link 的話，可以補上 .replace(/([\s+])@([^\s]+)/g, " <a href='https:\\/\\/example.com/$2'>@$2</a>")
  */
  const str = article.replace(/(\bhttps?:\/\/\S+)/g, '<a href="$1" target="_blank">$1</a>')
  return DOMPurify.sanitize(str, { ADD_ATTR: ['target'] })
}

function renderCheckItemsDescription ({ checkItems = [], items = [] }) {
  let description = ''
  const removeProductIds = []
  checkItems.forEach(checkItem => {
    const { checkStatus = null, productId = null, amount = 0, pass } = checkItem
    if (!pass) {
      const targetItem = items.find(i => i.productId === productId)
      if (targetItem) {
        switch (checkStatus) {
          case 'STOCK_NOT_ENOUGH':
            description += `${targetItem.name} 庫存只剩 ${amount > 0 ? amount : 0} 組\n`
            break
          case 'PRODUCT_NOT_EXISTS':
            description += `${targetItem.name} 目前店家不提供\n`
            removeProductIds.push(productId)
            break
          case 'CUSTOMER_VALUE_CARD_INSUFFICIENT_BALANCE':
            description += `${targetItem.name} 品項的儲值卡目前餘額不足。`
            break
          case 'CUSTOMER_VALUE_CARD_NOT_FOUND':
            description = `${targetItem.name} 品項的儲值卡目前不存在。`
            break
          case 'CUSTOMER_VALUE_CARD_FREEZE':
          case 'CUSTOMER_VALUE_CARD_IS_EXPIRED':
          case 'THE_CARD_IS_EXPIRED':
          case 'THE_CARD_HAS_BEEN_DEACTIVATED':
          case 'THE_CARD_CAN_NOT_USE_CARD_NO':
            description = `${targetItem.name} 品項的儲值卡目前已失效。`
            break
          case 'VOUCHER_OFFER_TARGETS_NOT_MATCH':
          case 'VOUCHER_DISCOUNT_CONDITION_NOT_MATCH':
            description = `${targetItem.name} 品項無法使用此優惠券。`
            break
          case 'VOUCHER_NOT_EXISTS':
          case 'VOUCHER_HAS_BEEN_DEACTIVATED':
          case 'VOUCHER_IS_EXPIRED':
          case 'VOUCHER_NOT_ENOUGH':
          case 'VOUCHER_LOCKED':
          case 'CUSTOMER_IS_REQUIRED':
            description = `${targetItem.name} 品項優惠券目前已失效。`
            break
        }
      }
    }
  })
  description += '導致訂購失敗。'
  return { description, removeProductIds }
}

function renderRetailOrderErrorText ({ status = '', checkItems = [], items = [], msg = '' }) {
  let title = '成立訂單失敗'
  let description = ''
  let removeProductIds = []
  if (['ITEMS_CHECK_FAILED', 'PRODUCT_NOT_EXISTS', 'STOCK_NOT_ENOUGH'].indexOf(status) > -1) {
    const { description: newDescription, removeProductIds: Ids } = renderCheckItemsDescription({ checkItems, items })
    description = newDescription
    removeProductIds = Ids
  }
  switch (status) {
    case 'DUPLICATED_KEY':
      description = '重複訂單結帳'
      break
    case 'PAY_FAILED':
      description = '付款失敗，請重新嘗試或更換付款方式，若有任何問題請聯絡店家。'
      break
    case 'CALCULATE_FAILED':
      description = '購物車金額試算失敗，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'ITEMS_CHECK_FAILED':
      title = '訂購失敗'
      break
    case 'STOCK_NOT_ENOUGH':
      title = '訂購失敗'
      break
    case 'CREATE_FAILED':
      description = '建立取貨單失敗，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'CUSTOMER_VALUE_CARD_INSUFFICIENT_BALANCE':
      description = '儲值卡目前餘額不足，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'CUSTOMER_VALUE_CARD_NOT_FOUND':
      description = '儲值卡目前不存在，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'CUSTOMER_VALUE_CARD_FREEZE':
    case 'CUSTOMER_VALUE_CARD_IS_EXPIRED':
    case 'THE_CARD_IS_EXPIRED':
    case 'THE_CARD_HAS_BEEN_DEACTIVATED':
    case 'THE_CARD_CAN_NOT_USE_CARD_NO':
      description = '儲值卡目前已失效，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'VOUCHER_OFFER_TARGETS_NOT_MATCH':
    case 'VOUCHER_DISCOUNT_CONDITION_NOT_MATCH':
      description = '優惠條件不足，無法使用此優惠券，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'VOUCHER_NOT_EXISTS':
    case 'VOUCHER_HAS_BEEN_DEACTIVATED':
    case 'VOUCHER_IS_EXPIRED':
    case 'VOUCHER_NOT_ENOUGH':
    case 'VOUCHER_LOCKED':
    case 'CUSTOMER_IS_REQUIRED':
      description = '優惠券目前已失效，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'PAYMENT_UNAVAILABLE':
      description = '目前無法使用該付款方式，請選擇其他付款方式。'
      break
    case 'RESOURCE_NOT_FOUND':
      title = '訂購失敗'
      description = '無此顧客，請重新登入'
      break
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      msg = `建立失敗（原因：${msg}）`
      break
  }
  return { title, description, removeProductIds }
}

function renderCheckEventItemsDescription ({ checkEvents = [], items = [] }) {
  let description = ''
  const nonExistProductName = []
  const nonExistProvider = []
  if (checkEvents.length > 0) {
    checkEvents.forEach(event => {
      const { checkStatus = null, productId = null, pass = true } = event
      if (!pass) {
        switch (checkStatus) {
          // 此時段無法實作此服務
          case 'NO_PROVIDERS':
            sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '目前人員無法提供服務，導致預約失敗' } })
            description = '預約該時段的服務人員已滿。'
            break
          // 人員被刪除
          case 'PRODUCT_PROVIDER_NOT_EXISTS':
            sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '目前人員無法提供服務，導致預約失敗' } })
            items.forEach(o => {
              if (o.productId === productId) {
                nonExistProvider.push(o.name)
              }
            })
            break
          // 若服務被刪除
          case 'PRODUCT_NOT_EXISTS':
            sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '目前店家無法提供服務，導致預約失敗' } })
            items.forEach(o => {
              if (o.productId === productId) {
                nonExistProductName.push(o.name)
              }
            })
            break
          case 'CUSTOMER_VALUE_CARD_INSUFFICIENT_BALANCE':
            sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '目前顧客儲值卡目前餘額不足，導致預約失敗' } })
            description = '儲值卡目前餘額不足，請重新嘗試，若有任何問題請聯絡店家。'
            break
          case 'CUSTOMER_VALUE_CARD_NOT_FOUND':
            sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '儲值卡目前不存在，導致預約失敗' } })
            description = '儲值卡目前不存在，請重新嘗試，若有任何問題請聯絡店家。'
            break
          case 'CUSTOMER_VALUE_CARD_FREEZE':
          case 'CUSTOMER_VALUE_CARD_IS_EXPIRED':
          case 'THE_CARD_IS_EXPIRED':
          case 'THE_CARD_HAS_BEEN_DEACTIVATED':
          case 'THE_CARD_CAN_NOT_USE_CARD_NO':
            sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '目前顧客儲值卡目前已失效，導致預約失敗' } })
            description = '儲值卡目前已失效，請重新嘗試，若有任何問題請聯絡店家。'
            break
          case 'VOUCHER_OFFER_TARGETS_NOT_MATCH':
          case 'VOUCHER_DISCOUNT_CONDITION_NOT_MATCH':
          case 'VOUCHER_NOT_EXISTS':
          case 'VOUCHER_HAS_BEEN_DEACTIVATED':
          case 'VOUCHER_IS_EXPIRED':
          case 'VOUCHER_NOT_ENOUGH':
          case 'VOUCHER_LOCKED':
          case 'CUSTOMER_IS_REQUIRED':
            description = ['VOUCHER_DISCOUNT_CONDITION_NOT_MATCH', 'VOUCHER_OFFER_TARGETS_NOT_MATCH'].indexOf(checkStatus) > -1 ? '優惠條件不足，無法使用此優惠券，請重新嘗試，若有任何問題請聯絡店家。' : '優惠券目前已失效，請重新嘗試，若有任何問題請聯絡店家。'
            sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: description } })
            break
        }
      }
    })
    if (nonExistProvider.length > 0) {
      // NOTE: 因過計算模組無法取得 userId 跟 isAssigned，因此調整文案
      description += `目前 ${uniq(nonExistProvider).join('、')} 的服務人員無法提供服務，導致預約失敗，請選擇其他服務人員預約。\n\n`
    }
    if (nonExistProductName.length > 0) {
      description += `目前店家無法提供服務「${nonExistProductName.join('、')}」，導致預約失敗，請選擇其他服務預約\n\n`
    }
  }
  return { description }
}

function renderBookingErrorText ({ status = '', checkEvents = [], items = [] }) {
  let title = '成立預約單失敗'
  let description = ''
  switch (status) {
    case 'CREATE_FAILED':
      title = '預約失敗'
      description = '建立預約失敗'
      break
    case 'UPDATE_FAILED':
      title = '預約失敗'
      description = '更新預約失敗'
      break
    case 'UNAUTHORIZED':
      title = '確認預約資訊失敗'
      description = '無此顧客，請重新登入'
      break
    case 'CALCULATE_FAILED':
      title = '預約失敗'
      description = '服務項目已經變動，請重新整理畫面'
      break
    case 'INVALID_OPERATIONS':
      title = '預約失敗'
      description = '預約時段已不提供預約，請重新選擇預約時段。'
      break
    case 'TOO_MANY_CHECKOUT_ATTEMPTS':
      title = '超過交易次數上限'
      description = '超過交易次數上限，請重新選擇\n預約時段。'
      break
    case 'INVALID_BOOKING_AMOUNT':
      title = '預約失敗'
      description = '訂單不得超過一萬元\n請重新調整項目再試一次'
      break
    case 'INVALID_BOOKING_START_TIME':
      title = '預約失敗'
      description = '僅開放30天內的預約\n請重新調整項目再試一次'
      break
    case 'EVENTS_CHECK_FAILED':
    case 'ITEMS_CHECK_FAILED':
    case 'PRODUCT_NOT_EXISTS':
      description = renderCheckEventItemsDescription({ checkEvents, items }).description
      break
    case 'PAY_FAILED':
      sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '目前付款失敗，導致預約失敗' } })
      description = '目前付款失敗，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'CUSTOMER_VALUE_CARD_INSUFFICIENT_BALANCE':
      sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '目前顧客儲值卡目前餘額不足，導致預約失敗' } })
      description = '儲值卡目前餘額不足，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'CUSTOMER_VALUE_CARD_NOT_FOUND':
      sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '儲值卡目前不存在，導致預約失敗' } })
      description = '儲值卡目前不存在，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'CUSTOMER_VALUE_CARD_FREEZE':
    case 'CUSTOMER_VALUE_CARD_IS_EXPIRED':
    case 'THE_CARD_IS_EXPIRED':
    case 'THE_CARD_HAS_BEEN_DEACTIVATED':
    case 'THE_CARD_CAN_NOT_USE_CARD_NO':
      sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '目前顧客儲值卡目前已失效，導致預約失敗' } })
      description = '儲值卡目前已失效，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'VOUCHER_NOT_EXISTS':
    case 'VOUCHER_HAS_BEEN_DEACTIVATED':
    case 'VOUCHER_IS_EXPIRED':
    case 'VOUCHER_LOCKED':
      sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '優惠券目前已失效，請重新嘗試，若有任何問題請聯絡店家。' } })
      description = '優惠券目前已失效，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'VOUCHER_OFFER_TARGETS_NOT_MATCH':
    case 'VOUCHER_DISCOUNT_CONDITION_NOT_MATCH':
      sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '優惠條件不足，無法使用此優惠券，請重新嘗試，若有任何問題請聯絡店家。' } })
      description = '優惠條件不足，無法使用此優惠券，請重新嘗試，若有任何問題請聯絡店家。'
      break
    case 'PAYMENT_UNAVAILABLE':
      sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '目前無法使用該付款方式，請選擇其他付款方式。' } })
      description = '目前無法使用該付款方式，請選擇其他付款方式。'
      break
    case 'INVALID_CUSTOMER':
      title = '預約失敗'
      sendMixpanelEvent({ title: '線上預約_點按確認預約資訊頁的預約失敗', data: { errorType: '如需預約請與店家聯繫' } })
      description = '如需預約請與店家聯繫'
      break
    default:
      description = `建立失敗（原因：${status}）`
      break
  }
  return { title, description }
}

function cloneDeepCustomizer (val) {
  if (isArray(val) && val.length === 0) {
    return []
  }
}

function sendAppMessage (appHandlerName = '', message = '') {
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[appHandlerName]) {
    switch (appHandlerName) {
      case 'closePageHandler':
        window.webkit.messageHandlers[appHandlerName].postMessage({ message })
        break
      default:
        window.webkit.messageHandlers[appHandlerName].postMessage({ message })
        break
    }
  }
}

function renderServiceTicketStatus (serviceTicketItem) {
  // NOTE: 計次券 Product 資料面上無過期狀態，需要用 endTime 來判斷
  const { serviceTicketInfo = {}, endTime: ticketEndTime, status } = serviceTicketItem
  const { endTime: productEndTime, deadlineType = 'Unlimited' } = serviceTicketInfo
  const referencedEndTime = ticketEndTime !== undefined ? ticketEndTime : productEndTime
  if (status === 'Disabled') {
    return 'Disabled'
  } else if (deadlineType === 'During' && referencedEndTime && getNow({ type: 'UnixTime' }) >= referencedEndTime) {
    return 'Expired'
  } else {
    return 'Active'
  }
}

function renderVoucherAllowEventList ({ offerTarget = 'All', targetIds = [], orderItems = [], products = [], categories = [] }) {
  let eventArr = []
  switch (offerTarget) {
    case 'Product':
      eventArr = orderItems.reduce((arr, eventItem) => {
        const { productId } = eventItem
        const productItem = products.find(p => p.id === productId)
        if (targetIds.includes(productId) && productItem) {
          arr.push(eventItem)
        }
        return arr
      }, [])
      break
    case 'Category': {
      const targetCategoryProductIds = targetIds.reduce((arr, value) => {
        const targetCategory = categories.find(c => c.id === value)
        if (targetCategory) {
          targetCategory.productIds.forEach(pid => {
            const productItem = products.find(p => p.id === pid)
            if (productItem) {
              arr.push(pid)
            }
          })
        }
        return arr
      }, [])
      eventArr = orderItems.reduce((arr, eventItem) => {
        const { productId } = eventItem
        if (targetCategoryProductIds.includes(productId)) {
          arr.push(eventItem)
        }
        return arr
      }, [])
      break
    }
    case 'All':
    default:
      eventArr = orderItems
      break
  }
  return eventArr
}

const renderOrderDateFormat = (value) => renderUnixTimeFormat({ timestamp: value, format: 'yyyy/LL/dd HH:mm' })

const getAppointPaymentStatus = (appointment) => {
  const { payments = [], paymentStatus = '' } = appointment
  /*
    NOTE: paymentStatus 狀態列表
    APP 不採用外層的 paymentStatus，僅線上預約有此欄位
    None: 已選擇現場付款
    Unpaid: 未付款
    Paid: 已付款
    Failed: 付款失敗
    Pending: 待付款
  */
  return (payments.length === 0) ? paymentStatus : get(payments, '[0].status', '')
}

const isSamePageRedirect = () => {
  // NOTE: 在 IAB 或 Safari 情況下都需要當頁跳轉才能成功 (Safari 不允許新開視窗，故僅能在原頁面跳轉)
  const userAgent = navigator.userAgent
  const inAppRules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv)']
  const inAppRegex = new RegExp(`(${inAppRules.join('|')})`, 'ig')
  return (userAgent.indexOf('Safari') > -1 && !userAgent.match('CriOS') && userAgent.indexOf('Chrome') === -1) || userAgent.match(inAppRegex)
}

export {
  renderOrderDateFormat,
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  addDatadogGlobalContext,
  sendDatadogLog,
  formatThousandth,
  formatCurrency,
  formatDiscountText,
  formatRangeByKey,
  formatWeekdayNames,
  formatAppointmentStatus,
  formatDurationText,
  formatBirth,
  formatPaymentMethod,
  formatVersion,
  renderDurationOptions,
  getCancelCondition,
  getProvideUserList,
  getWebVersion,
  formatBookingOriginType,
  getRefundAmountListFromAPI,
  sendMixpanelEvent,
  decodeQueryURL,
  renderUserProtectionText,
  renderVoucherDiscountText,
  checkDataDogLog,
  convertTextWithLink,
  renderRetailOrderErrorText,
  renderBookingErrorText,
  cloneDeepCustomizer,
  sendAppMessage,
  renderServiceTicketStatus,
  renderVoucherAllowEventList,
  getAppointPaymentStatus,
  isSamePageRedirect,
}
