import { cloneDeep } from 'lodash-es'

import API from '@/api'
import { addDatadogGlobalContext, getLocalStorage, setLocalStorage } from '@/utils'

export const state = {
  token: null,
  userId: null,
  expireTime: 0
}
export const actions = {
  async login ({ commit }, { lineToken, code, userId }) {
    const result = await API.login({ lineToken, code, userId })
    commit('setLogin', result)
    return result
  },
  async autoLogin ({ commit }, authInfo) {
    commit('setLoginInfo', authInfo)
  },
  async register ({ commit }, { registerInfo, code }) {
    const result = await API.register({ userInfo: registerInfo, code })
    commit('setLogin', result)
    return result
  },
  async sendValidateCode (_, { phoneNumber, regionCode = 'TW', code, sendType = 'sms', smsSupplier = 'paasoo' }) {
    const result = await API.sendValidateCode({ phoneNumber, regionCode, code, sendType, smsSupplier })
    return result
  },
  async validatePhoneNumber (_, { phoneNumber, regionCode = 'TW', code }) {
    const result = await API.validatePhoneNumber({ phoneNumber, regionCode, code })
    return result
  }
}

export const mutations = {
  setLogin (state, data) {
    const { status = '', token = null, expireTime = 0, userId = null, gender = null, sourceId = null } = data
    if (status === 'SUCCESS') {
      state.token = token
      state.expireTime = expireTime
      state.userId = userId
      state.gender = gender
      state.sourceId = sourceId
      const originStorageData = getLocalStorage('User')
      const formatObj = originStorageData ? Object.assign(originStorageData, { token, expireTime, userId, gender, sourceId }) : data
      addDatadogGlobalContext({ key: 'userId', value: userId })
      setLocalStorage('User', formatObj)
    }
  },
  setLoginInfo (_, data) {
    const { status = '', token = null, expireTime = 0, userId = null, gender = null, sourceId = null } = data
    if (status === 'SUCCESS') {
      const originStorageData = getLocalStorage('User')
      const newStorageData = cloneDeep(originStorageData)
      newStorageData.token = token
      newStorageData.expireTime = expireTime
      newStorageData.userId = userId
      newStorageData.gender = gender
      newStorageData.sourceId = sourceId
      addDatadogGlobalContext({ key: 'userId', value: newStorageData.userId })
      setLocalStorage('User', newStorageData)
    }
  }
}
export const getters = {
  isLoggedIn: state => !!state.token
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
