import { get } from 'lodash-es'

import API from '@/api'
import { getLocalStorage, getProvideUserList, setLocalStorage } from '@/utils'
import { getDateTimeByJSDate, getNow } from '@/utils/timeFormat'

export const state = {
  service: {
    events: [],
    customerCount: 1,
    selectedDate: getNow({ type: 'UnixTime' }),
    selectedTime: null,
    selectedOptions: [],
    voucherIds: [],
    userId: null,
    customerNotes: '',
    customerValueCardId: '',
    customerVoucherId: '',
    voucherDiscountCondition: 'EveryOne',
    voucherDiscountEventId: '',
    isAgree: false
  },
  tempBooking: {},
  booking: {},
  retail: {
    items: [],
    voucherIds: [],
    userId: null,
    customerNotes: ''
  },
  orderDetailData: {}
}
export const actions = {
  addCustomerCount ({ commit }, { cname, customerCount }) {
    commit('addCustomerCount', { cname, customerCount })
  },
  addCartItem ({ commit }, { cname, item }) {
    commit('addEventItem', { cname, item })
  },
  removeCartItem ({ commit }, { cname, itemIndex }) {
    commit('removeEventItem', { cname, itemIndex })
  },
  updateCartItem ({ commit }, { cname, data }) {
    commit('updateEventItem', { cname, data })
  },
  setBooking ({ commit }, data) {
    commit('setBooking', data)
  },
  setTempBooking ({ commit }, { code, data }) {
    commit('setTempBooking', { code, data })
  },
  setServiceCart ({ commit }, { cname, data }) {
    commit('setServiceCart', { cname, data })
  },
  setOrderDetailData ({ commit }, data) {
    commit('setOrderDetailData', data)
  },
  setUserSelectDate ({ commit }, { cname, selectedDate }) {
    commit('setUserSelectDate', { cname, selectedDate })
  },
  setUserSelectTime ({ commit }, { cname, selectedTime }) {
    commit('setUserSelectTime', { cname, selectedTime })
  },
  setUserParallel ({ commit }, { cname, selectedOptions }) {
    commit('setUserParallel', { cname, selectedOptions })
  },
  resetCart ({ commit }, cname) {
    commit('resetCart', cname)
  },
  resetBooking ({ commit }, cname) {
    commit('resetBooking', cname)
  },
  resetTempBooking ({ commit }, cname) {
    commit('resetTempBooking', cname)
  },
  resetServiceCart ({ commit }, cname) {
    commit('resetServiceCart', cname)
  },
  resetRetailCart ({ commit }, cname) {
    commit('resetRetailCart', cname)
  },
  setCheckoutOtherInfo ({ commit }, { code, data }) {
    commit('setCheckoutOtherInfo', { code, data })
  },
  loadCartInitData ({ commit }, { cname, storeInfo, products, users, query, maxCustomerCount }) {
    commit('loadCartInitData', { cname, storeInfo, products, users, query, maxCustomerCount })
  },
  updateRetailItemQuantity ({ commit }, { code, data }) {
    commit('updateRetailItemQuantity', { code, data })
  },
  setRetailCheckoutData ({ commit }, { code, data }) {
    commit('setRetailCheckoutData', { code, data })
  },
  async sendBookingInfo (_, { code, data }) {
    const result = await API.sendBookingInfo({ code, data })
    return result
  },
  async updateBookingInfo (_, { code, data }) {
    const result = await API.updateBookingInfo({ code, data })
    return result
  },
  async removeTempBookingInfo ({ commit }, { code, data }) {
    const result = await API.removeTempBookingInfo({ code, data })
    commit('resetTempBooking', code)
    return result
  },
  async checkItems (_, { code, data }) {
    const result = await API.checkItems({ code, data })
    return result
  },
  async checkoutCart (_, { code, data }) {
    const result = await API.checkout({ code, data })
    return result
  },
  async getBookings (_, { code }) {
    const result = await API.getBookings({ code })
    return result
  },
  async getBookingById (_, { code, bookingId }) {
    const result = await API.getBookingById({ code, bookingId })
    return result
  },
  async createPickupOrder (_, { code, data }) {
    const result = await API.createPickupOrder({ code, data })
    return result
  },
  async updatePickupOrder (_, { code, data }) {
    const result = await API.updatePickupOrder({ code, data })
    return result
  },
  async getPickupOrderById (_, { code, pickId }) {
    const result = await API.getPickupOrderById({ code, pickId })
    return result
  }
}

export const mutations = {
  setBooking (state, data) {
    state.booking = data
  },
  setTempBooking (state, { code, data }) {
    state.tempBooking = data
    const storeLocalStorage = getLocalStorage(code)
    if (!storeLocalStorage?.tempBooking) {
      storeLocalStorage.tempBooking = data
    } else {
      Object.assign(storeLocalStorage.tempBooking, data)
    }
    setLocalStorage(code, storeLocalStorage)
  },
  setOrderDetailData (state, data) {
    state.orderDetailData = data
  },
  setServiceCart (state, { cname, data }) {
    Object.assign(state.service, data)
    const storeLocalStorage = getLocalStorage(cname)
    if (!storeLocalStorage.service) {
      storeLocalStorage.service = data
    } else {
      Object.assign(storeLocalStorage.service, data)
    }
    setLocalStorage(cname, storeLocalStorage)
  },
  setCheckoutOtherInfo (state, { code, data }) {
    Object.assign(state.booking, data)
    const storeLocalStorage = getLocalStorage(code)
    if (get(storeLocalStorage, 'booking', null)) {
      Object.assign(storeLocalStorage.booking, data)
      setLocalStorage(code, storeLocalStorage)
    }
  },
  resetCart (state, cname) {
    state.service = { events: [], customerCount: 1 }
    state.retail = { items: [] }
    state.tempBooking = {}
    state.booking = {}
    const storeLocalStorage = getLocalStorage(cname)
    if (storeLocalStorage) {
      if (get(storeLocalStorage, 'service', null)) {
        delete storeLocalStorage.service
      }
      storeLocalStorage.booking = null
      storeLocalStorage.tempBooking = null
      storeLocalStorage.service = {
        events: [],
        customerCount: 1,
        voucherIds: [],
        userId: null,
        customerNotes: '',
        selectedDate: getNow({ type: 'UnixTime' }),
        selectedTime: null,
        selectedOptions: []
      }
      storeLocalStorage.retail = { items: [] }
      setLocalStorage(cname, storeLocalStorage)
    }
  },
  resetServiceCart (state, cname) {
    const initServiceObj = {
      events: [],
      customerCount: 1,
      voucherIds: [],
      userId: null,
      customerNotes: '',
      selectedDate: getNow({ type: 'UnixTime' }),
      selectedTime: null,
      selectedOptions: []
    }
    state.service = { ...initServiceObj }
    const storeLocalStorage = getLocalStorage(cname)
    if (storeLocalStorage) {
      if (get(storeLocalStorage, 'service', null)) {
        delete storeLocalStorage.service
      }
      storeLocalStorage.service = { ...initServiceObj }
      setLocalStorage(cname, storeLocalStorage)
    }
  },
  resetRetailCart (state, cname) {
    state.retail = { items: [] }
    const storeLocalStorage = getLocalStorage(cname)
    if (storeLocalStorage) {
      if (get(storeLocalStorage, 'cart', null)) {
        delete storeLocalStorage.cart
      }
      storeLocalStorage.retail = { items: [] }
      setLocalStorage(cname, storeLocalStorage)
    }
  },
  loadCartInitData (state, { cname, storeInfo, products, users, query, maxCustomerCount }) {
    const storeLocalStorage = getLocalStorage(cname)
    const obj = { events: [], customerCount: 1, selectedDate: getNow({ type: 'UnixTime' }), selectedTime: null, selectedOptions: [] }
    if (storeLocalStorage) {
      state.service = get(storeLocalStorage, 'service', obj)
      if (state.service?.selectedDate === undefined || state.service?.selectedDate === null) {
        state.service.selectedDate = getNow({ type: 'UnixTime' })
      } else if (typeof state.service.selectedDate !== 'number' && state.service.selectedDate) {
        if (new Date().getTime() === getNow({ type: 'Timestamp' })) {
          // 使用者的 JS Date 是同時區
          const dt = getDateTimeByJSDate({ date: state.service.selectedDate })
          state.service.selectedDate = dt.toUnixInteger()
        } else {
          // NOTE: (Edge Case) 該筆預約跟使用者目前時區不同，直接重設成現在時間的 unix
          state.service.selectedDate = getNow({ type: 'UnixTime' })
        }
      }
      if (state.service?.selectedTime === undefined) {
        state.service.selectedTime = null
      }
      if (state.service?.selectedOptions === undefined) {
        state.service.selectedOptions = []
      }
      // NOTE: 檢查商品是否存在
      if (state.service.events.length > 0) {
        const isExistEvents = []
        state.service.events.forEach(event => {
          const product = products.find(p => p.id === event.id)
          if (product) {
            /// 更新 product 上的資訊
            const { price: productPrice, duration: productDuration, providers = [], isAssigned: productAssigned = false, subItemType = 'Single', isShowSubItem = false, subItemProviders = [], subItems = [] } = product
            Object.assign(event, { price: productPrice, duration: productDuration, providers, isAssigned: productAssigned, subItemType, isShowSubItem, subItemProviders, subItems })
            const arrangeUsers = getProvideUserList({ storeInfo, product: event, users, query })
            if (arrangeUsers.length > 0) {
              // NOTE: 檢查原服務人員是否有提供服務，如果原服務人員不存在，就選可以服務的第一個人員
              if (event.userId) {
                const targetUser = arrangeUsers.find(u => u.id === event.userId)
                if (!targetUser) {
                  event.userId = arrangeUsers[0].id
                  event.userName = arrangeUsers[0].name
                }
              }
              if (event?.realUserId) {
                const targetRealUser = arrangeUsers.find(u => u.id === event?.realUserId)
                if (!targetRealUser) {
                  event.realUserId = event.userId
                  event.realUserName = event.userName
                }
              }
              // NOTE: 子項目檢查
              if (subItemType !== 'Single' && !isShowSubItem) {
                // 不需要顯示子項目
                event.subItemId = null
              } else if (subItemType !== 'Single' && isShowSubItem && event.subItemId && subItems.findIndex(subItem => subItem.id === event.subItemId) === -1) {
                // 如有 subItem id 卻不存在於目前的 subItems 就清空
                event.subItemId = null
              }
              if (subItemType === 'Single' || (subItemType !== 'Single' && (isShowSubItem ? event.subItemId !== null : true))) {
                // 資料正確的服務才會重新載入
                isExistEvents.push(event)
              }
            }
          }
        })
        state.service.events = isExistEvents
        const realCustomerCount = maxCustomerCount >= state.service.customerCount ? state.service.customerCount : 1
        if (state.service.selectedOptions.length > 0 && realCustomerCount === 1) {
          // 啟用多位進行且最多顧客僅 1 人，自動重設為 1 人
          state.service.selectedOptions = []
        }
        Object.assign(storeLocalStorage.service, { events: isExistEvents, customerCount: realCustomerCount, selectedOptions: state.service.selectedOptions })
        setLocalStorage(cname, storeLocalStorage)
      }
    } else {
      state.service = obj
    }
  },
  addEventItem (state, { cname, item }) {
    state.service.events.push(item)
    const storeLocalStorage = getLocalStorage(cname)
    if (storeLocalStorage) {
      if (storeLocalStorage.service) {
        const storeCartEvents = get(storeLocalStorage.service, 'events', [])
        storeCartEvents.push(item)
      } else {
        Object.assign(storeLocalStorage, { service: { events: [item] } })
      }
      setLocalStorage(cname, storeLocalStorage)
    } else {
      setLocalStorage(cname, { service: { events: [item] } })
    }
  },
  removeEventItem (_, { cname, itemIndex }) {
    const storeLocalStorage = getLocalStorage(cname)
    if (storeLocalStorage) {
      if (storeLocalStorage.service) {
        const storeCartEvents = get(storeLocalStorage.service, 'events', [])
        if (storeCartEvents.length - 1 >= itemIndex) {
          storeCartEvents.splice(itemIndex, 1)
          setLocalStorage(cname, storeLocalStorage)
        }
      }
    }
  },
  updateEventItem (state, { cname, data }) {
    const { item = null, index = 0 } = data
    state.service.events[index] = item
    const storeLocalStorage = getLocalStorage(cname)
    if (storeLocalStorage) {
      if (storeLocalStorage.service) {
        storeLocalStorage.service.events[index] = item
      }
      setLocalStorage(cname, storeLocalStorage)
    }
  },
  addCustomerCount (state, { cname, customerCount }) {
    state.service.customerCount = customerCount
    const storeLocalStorage = getLocalStorage(cname)
    if (storeLocalStorage) {
      if (storeLocalStorage.service) {
        Object.assign(storeLocalStorage.service, { customerCount: state.service.customerCount })
      } else {
        Object.assign(storeLocalStorage, { service: { events: [], customerCount } })
      }
      setLocalStorage(cname, storeLocalStorage)
    } else {
      setLocalStorage(cname, { service: { events: [], customerCount } })
    }
  },
  setUserSelectDate (state, { cname, selectedDate }) {
    state.service.selectedDate = selectedDate
    const storeLocalStorage = getLocalStorage(cname)
    if (storeLocalStorage) {
      if (storeLocalStorage.service) {
        Object.assign(storeLocalStorage.service, { selectedDate })
      } else {
        Object.assign(storeLocalStorage, { service: { events: [], selectedDate } })
      }
      setLocalStorage(cname, storeLocalStorage)
    } else {
      setLocalStorage(cname, { service: { events: [], selectedDate } })
    }
  },
  setUserSelectTime (state, { cname, selectedTime }) {
    state.service.selectedTime = selectedTime
    const storeLocalStorage = getLocalStorage(cname)
    if (storeLocalStorage) {
      if (storeLocalStorage.service) {
        Object.assign(storeLocalStorage.service, { selectedTime: state.service.selectedTime })
        setLocalStorage(cname, storeLocalStorage)
      }
    }
  },
  setUserParallel (state, { cname, selectedOptions }) {
    state.service.selectedOptions = selectedOptions
    const storeLocalStorage = getLocalStorage(cname)
    if (storeLocalStorage) {
      if (storeLocalStorage.service) {
        Object.assign(storeLocalStorage.service, { selectedOptions: state.service.selectedOptions })
      } else {
        Object.assign(storeLocalStorage, { service: { events: [], selectedOptions: [] } })
      }
      setLocalStorage(cname, storeLocalStorage)
    } else {
      setLocalStorage(cname, { service: { events: [], selectedOptions: [] } })
    }
  },
  resetTempBooking (state, cname) {
    state.tempBooking = {}
    const storeLocalStorage = getLocalStorage(cname)
    if (get(storeLocalStorage, 'tempBooking', null)) {
      storeLocalStorage.tempBooking = null
      setLocalStorage(cname, storeLocalStorage)
    }
  },
  resetBooking (state, cname) {
    state.booking = {}
    const storeLocalStorage = getLocalStorage(cname)
    if (get(storeLocalStorage, 'booking', null)) {
      storeLocalStorage.booking = null
      setLocalStorage(cname, storeLocalStorage)
    }
  },
  updateRetailItemQuantity (state, { code, data }) {
    const { productId, quantity, ...obj } = data
    const targetIndex = state.retail.items.findIndex(product => product.productId === productId)
    if (targetIndex > -1) {
      const newQuantity = state.retail.items[targetIndex].quantity + quantity
      state.retail.items.splice(targetIndex, 1, Object.assign(state.retail.items[targetIndex], obj, { quantity: newQuantity }))
    } else {
      state.retail.items.push(data)
    }
    let storeLocalStorage = getLocalStorage(code)
    if (storeLocalStorage && get(storeLocalStorage, 'retail', []).length > 0) {
      storeLocalStorage.retail.items = state.retail.items
    } else {
      storeLocalStorage = {}
      Object.assign(storeLocalStorage, { retail: { items: state.retail.items } })
    }
    setLocalStorage(code, storeLocalStorage)
  },
  setRetailCheckoutData (state, { code, data }) {
    if (!state.retail) {
      state.retail = {}
    }
    Object.assign(state.retail, data)
    let storeLocalStorage = getLocalStorage(code)
    if (get(storeLocalStorage, 'retail', null)) {
      Object.assign(storeLocalStorage.retail, data)
    } else {
      storeLocalStorage = { retail: data }
    }
    setLocalStorage(code, storeLocalStorage)
  }
}
export const getters = {
  serviceCart: state => state.service || {},
  serviceEvents: state => state.service.events || [],
  customerCount: state => state.service.customerCount || 1,
  selectedDate: state => state.service.selectedDate || getNow({ type: 'UnixTime' }),
  selectedTime: state => state.service.selectedTime || null,
  selectedOptions: state => state.service.selectedOptions || [],
  booking: state => state.booking || {},
  retailCart: state => state.retail.items || [],
  orderDetailData: state => state.orderDetailData || {}
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
