import { createStore } from 'vuex'

import auth from '@/store/modules/auth'
import cart from '@/store/modules/cart'
import form from '@/store/modules/form'
import store from '@/store/modules/store'
import user from '@/store/modules/user'
import voucher from '@/store/modules/voucher'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    cart,
    store,
    auth,
    voucher,
    form
  }
})
