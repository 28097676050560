import '@/assets/scss/index.scss'
import { datadogLogs } from '@datadog/browser-logs'
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import ElementUI from 'element-plus'
import zhTW from 'element-plus/dist/locale/zh-tw.mjs'
import mixpanel from 'mixpanel-browser'
import ResizeObserver from 'resize-observer-polyfill'
import VueAxios from 'vue-axios'

import App from '@/App.vue'
import router from '@/router/index.js'
import store from '@/store/index.js'

window.ResizeObserver = ResizeObserver

const app = createApp({
  render: () => h(App)
})

datadogLogs.init({
  clientToken: import.meta.env.VITE_DATADOG_TOKEN,
  site: 'datadoghq.com',
  forwardErrorsToLogs: import.meta.env.VITE_FORWARD_ERRORS_TO_LOGS,
  service: import.meta.env.VITE_DATADOG_SERVICE,
  version: import.meta.env.VITE_APP_VERSION.split('(')[0],
  beforeSend: (log) => {
    if (log.type === 'error') {
      const ignoreErrors = [
        'api-mixpanel',
        'Script error'
      ]
      if (ignoreErrors.some(value => log.error.message.includes(value))) {
        return false
      }
    }
  }
})

datadogLogs.setLoggerGlobalContext({
  env: import.meta.env.VITE_NODE_ENV.charAt(0).toUpperCase() + import.meta.env.VITE_NODE_ENV.slice(1),
  browser: window.navigator.userAgent,
  appName: import.meta.env.VITE_DATADOG_SERVICE,
  appVersion: import.meta.env.VITE_APP_VERSION,
  source: 'browser'
})

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, { debug: import.meta.env.VITE_NODE_ENV !== 'production', verbose: import.meta.env.VITE_NODE_ENV !== 'production' })

// if (import.meta.env.NODE_ENV === 'production') {
Sentry.init({
  app,
  dsn: 'https://7c4188d180136b5e52410fd0e020beb1@o4506855679983616.ingest.us.sentry.io/4506856370012160',
  environment: import.meta.env.VITE_NODE_ENV.charAt(0).toUpperCase() + import.meta.env.VITE_NODE_ENV.slice(1),
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  release: 'venus-frontend-appointment@' + import.meta.env.VITE_APP_VERSION,
  ignoreErrors: [
    /api-js.mixpanel.com\/track/,
    'Script error.',
    'Network Error'
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})
// }

app.use(router)
app.use(store)
app.use(ElementUI, { locale: zhTW })
app.use(VueAxios, axios)

app.mount('#app')
