import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'
import { sendDatadogLog } from '@/utils'

// Containers
const TheContainer = () => import('@/views/containers/TheContainer.vue')

// Views - Pages
const Booking = () => import('@/views/pages/Booking.vue')
const BookingDetail = () => import('@/views/pages/BookingDetail.vue')
const VoucherDetail = () => import('@/views/pages/VoucherDetail.vue')
const FormDetail = () => import('@/views/pages/FormDetail.vue')
const Checkout = () => import('@/views/pages/Checkout.vue')
const BookingDeposit = () => import('@/views/pages/BookingDeposit.vue')
const Payment = () => import('@/views/pages/Payment.vue')
const Record = () => import('@/views/pages/Record.vue')
const RecordDetail = () => import('@/views/pages/RecordDetail.vue')
const Store = () => import('@/views/pages/Store.vue')
const StoreHQ = () => import('@/views/pages/StoreHQ.vue')
const StoreMenu = () => import('@/views/pages/StoreMenu.vue')
const StoreRetail = () => import('@/views/pages/retail/StoreRetail.vue')
const RetailMenuDetail = () => import('@/views/pages/retail/RetailMenuDetail.vue')
const RetailCheckout = () => import('@/views/pages/retail/RetailCheckout.vue')
const RetailOrderDetail = () => import('@/views/pages/retail/RetailOrderDetail.vue')
const UserLogin = () => import('@/views/pages/UserLogin.vue')
const UserProfile = () => import('@/views/pages/UserProfile.vue')
const UserVoucher = () => import('@/views/pages/UserVoucher.vue')
const UserValueCard = () => import('@/views/pages/UserValueCard.vue')
const Terms = () => import('@/views/pages/Terms.vue')
const NotFound = () => import('@/views/pages/NotFound.vue')
const Message = () => import('@/views/pages/Message.vue')
const ElementPage = () => import('@/views/pages/ElementPage.vue')
const TxRedirectPage = () => import('@/views/pages/TxRedirectPage.vue')

function configRoutes () {
  return [
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
    {
      path: '/elementUI',
      name: 'ElementUI',
      component: ElementPage
    },
    {
      path: '/',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'UserLogin',
          meta: {
            label: '登入',
            passAuth: false
          },
          component: UserLogin
        },
        {
          path: 'terms',
          name: 'Terms',
          meta: {
            label: '服務條款',
            passAuth: false
          },
          component: Terms
        },
        {
          path: 'store',
          name: 'Store',
          meta: {
            label: '商店入口',
            passAuth: true
          },
          component: Store
        },
        {
          path: 'store/hq/:hqId?',
          name: 'StoreHQ',
          meta: {
            label: '商店列表',
            passAuth: true
          },
          component: StoreHQ
        },
        {
          path: 'menu/:pid?',
          name: 'StoreMenu',
          meta: {
            label: '服務列表',
            passAuth: true
          },
          component: StoreMenu
        },
        {
          path: 'retail',
          name: 'StoreRetail',
          meta: {
            label: '商品列表',
            passAuth: true
          },
          component: StoreRetail
        },
        {
          path: 'merchandise',
          name: 'RetailMenuDetail',
          meta: {
            label: '商品詳細列表',
            passAuth: false
          },
          component: RetailMenuDetail
        },
        {
          path: 'booking',
          name: 'Booking',
          meta: {
            label: '預約',
            passAuth: true
          },
          component: Booking
        },
        {
          path: 'deposit/:bookingId',
          name: 'BookingDeposit',
          meta: {
            label: '預約單付款',
            passAuth: false
          },
          component: BookingDeposit
        },
        {
          path: 'payment/:type',
          name: 'Payment',
          meta: {
            label: '付款',
            passAuth: true
          },
          component: Payment
        },
        {
          path: 'checkout',
          name: 'Checkout',
          meta: {
            label: '結帳',
            passAuth: true
          },
          component: Checkout
        },
        {
          path: 'buy',
          name: 'Buy',
          meta: {
            label: '商品結帳',
            passAuth: true
          },
          component: RetailCheckout
        },
        {
          path: 'record',
          name: 'Record',
          meta: {
            label: '我的紀錄',
            passAuth: true
          },
          component: Record
        },
        {
          path: 'detail',
          name: 'RecordDetail',
          meta: {
            label: '交易紀錄',
            passAuth: true
          },
          component: RecordDetail
        },
        {
          path: 'booking/:bookingId',
          name: 'BookingDetail',
          meta: {
            label: '預約服務詳情',
            passAuth: false
          },
          component: BookingDetail
        },
        {
          path: 'pickupOrder/:pickId',
          name: 'RetailOrderDetail',
          meta: {
            label: '商品訂購詳情',
            passAuth: false
          },
          component: RetailOrderDetail
        },
        {
          path: 'user',
          meta: {
            label: '使用者',
            passAuth: true
          },
          component: {
            render () {
              return h(resolveComponent('router-view'))
            }
          },
          children: [
            {
              path: '',
              redirect: '/user/profile'
            },
            {
              path: 'profile',
              name: 'UserProfile',
              meta: {
                label: '使用者資訊'
              },
              component: UserProfile
            },
            {
              path: 'voucher',
              name: 'UserVoucher',
              meta: {
                label: '優惠券列表'
              },
              component: UserVoucher
            },
            {
              path: 'valueCard',
              name: 'UserValueCard',
              meta: {
                label: '我的票卡'
              },
              component: UserValueCard
            }
          ]
        },
        {
          path: 'message',
          name: 'Message',
          meta: {
            label: '訊息顯示',
            passAuth: false
          },
          component: Message
        },
        {
          path: 'voucher/:voucherId',
          name: 'VoucherDetail',
          meta: {
            label: '優惠券詳情',
            passAuth: false
          },
          component: VoucherDetail
        },
        {
          path: 'form/:formId',
          name: 'FormDetail',
          meta: {
            label: '單一表單內容',
            passAuth: false
          },
          component: FormDetail
        },
        {
          path: 'bookingTx/:orderId?/:status?',
          name: 'BookingTx',
          meta: {
            label: '預約單金流中轉頁',
            passAuth: false
          },
          component: TxRedirectPage
        },
        {
          path: 'pickupTx/:orderId?/:status?',
          name: 'PickupTx',
          meta: {
            label: '店販單金流中轉頁',
            passAuth: false
          },
          component: TxRedirectPage
        }
      ]
    }
  ]
}

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  scrollBehavior: () => ({ top: 0 }),
  routes: configRoutes()
})

function redirect (to, needPassAuth, authData) {
  const authToken = authData ? authData.token : null
  const isLoggedIn = authToken !== null
  if (!needPassAuth || (needPassAuth && isLoggedIn)) {
    return true
  }
  // NOTE: 如遇到 needPassAuth = true 的頁面要檢查，跳轉頁面用的參數是否都在 Query 上
  return {
    name: 'UserLogin',
    query: to.query,
    replace: true
  }
}

router.beforeEach(async (to, _, next) => {
  const needPassAuth = to.matched.some(record => record.meta.passAuth)
  const localStorageData = localStorage.getItem('QLiEERBeautyBooking-User')
  const authData = JSON.parse(localStorageData)
  const authToken = authData ? authData.token : null
  const isLoggedIn = authToken !== null
  if (!isLoggedIn) {
    const now = Math.round(Date.now() / 1000)
    if (authToken && authData.expireTime > now) {
      await store.dispatch('auth/autoLogin', authData)
    }
  }
  next(redirect(to, needPassAuth, authData))
})

router.onError((error, to) => {
  const reloadErrors = [
    'Failed to fetch dynamically imported module',
    'Importing a module script failed'
  ]
  sendDatadogLog({ message: 'test router error', data: { error, to }, status: 'debug' })
  if (reloadErrors.some(value => error.message.includes(value))) {
    window.location.href = to.fullPath
  }
})

export default router
