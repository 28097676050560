import API from '@/api'
import { addDatadogGlobalContext, getLocalStorage, setLocalStorage } from '@/utils'

let lastRequestId = 0

export const state = {
  products: [],
  categories: [],
  users: [],
  availableTimes: {},
  setting: {
    // Store Setting
    storeId: null,
    name: '',
    code: '',
    address: '',
    description: '',
    phone: '',
    type: [],
    banner: null,
    logo: null,
    serviceEnable: false,
    retailEnable: false,
    consultEnable: false,
    pickupTimeEnable: true,
    consultURL: '',
    paymentMethods: [],
    openingHours: {
      open: {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: []
      },
      rest: {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: []
      }
    },
    workingHours: {
      open: {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: []
      },
      rest: {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: []
      }
    },
    bookingTimeOffset: 60,
    cancelCondition: [],
    deposit: {
      required: false,
      depositValue: 1
    },
    notes: '',
    unassignedMode: 'Normal',
    customerCountEnable: true,
    // 服務人員個人網站資訊
    websiteBanner: [],
    websiteTitle: '',
    websiteDescription: ''
  }
}
export const actions = {
  async getStore ({ commit }, { code, userId }) {
    if (code === '') return
    const obj = userId ? { code, userId } : { code }
    const result = await API.getStore(obj)
    if (result.status === 'SUCCESS') {
      commit('updateStoreData', result.data)
    }
    return result
  },
  async getMenuByType ({ commit }, { menuType = 'service', code, userId, customerId = '' }) {
    const obj = userId ? { code, userId, customerId } : { code, customerId }
    const result = (menuType === 'retail') ? await API.getRetailMenu(obj) : await API.getServiceMenu(obj)
    if (result.status === 'SUCCESS') {
      commit('updateMenuByType', result.data)
    }
    return result
  },
  async getProductById (_, { code, productId, userId }) {
    const obj = userId ? { code, productId, userId } : { code, productId }
    const result = await API.getProductById(obj)
    return result
  },
  async getAvailableTimes ({ commit }, { code, startTime, endTime, events, parallel, userId, customerCount }) {
    const currentRequestId = ++lastRequestId
    const result = await API.getBookingTimes({ code, startTime, endTime, events, parallel, userId, customerCount })
    if (result.status === 'SUCCESS' && currentRequestId === lastRequestId) {
      commit('availableTimes')
    }
    return result
  },
  async getAvailablePickupTime (_, { code, startTime, endTime }) {
    const result = await API.getAvailablePickupTime({ code, startTime, endTime })
    return result
  }
}

export const mutations = {
  updateMenuByType (state, data) {
    const { storeId, name: _name, type: _type, products = [], categories = [], users = [], ...otherSetting } = data
    Object.assign(state.setting, { storeId }, otherSetting)
    state.products = products
    state.categories = categories
    state.users = users
    setLocalStorage('Store', { storeId })
  },
  updateStoreData (state, data) {
    const storeLocalStorage = getLocalStorage('Store')
    const { storeId, name, type } = data
    const newStoreLocalStorage = Object.assign({} || storeLocalStorage, { storeId, name, type })
    Object.assign(state.setting, { ...data })
    addDatadogGlobalContext({ key: 'storeId', value: storeId })
    addDatadogGlobalContext({ key: 'storeName', value: name })
    setLocalStorage('Store', newStoreLocalStorage)
  },
  availableTimes (state, data) {
    state.availableTimes = data
  }
}

export const getters = {
  storeSetting: state => state.setting,
  storeName: state => state.setting.name,
  products: state => state.products,
  categories: state => state.categories,
  users: state => state.users,
  pickupTimeEnable: state => state.setting.pickupTimeEnable,
  depositRequired: state => state.deposit?.required || false
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
