import API from '@/api'

export const state = {
  formData: {}
}

export const actions = {
  async getFormById ({ commit }, { code, formId }) {
    const result = await API.getFormById({ code, formId })
    commit('setFormData', result.status === 'SUCCESS' ? result.data : {})
    return result
  },
  async getViewFormById ({ commit }, { code, formId, useAuth }) {
    const result = await API.getViewFormById({ code, formId, useAuth })
    commit('setFormData', result.status === 'SUCCESS' ? result.data : {})
    return result
  },
  async updateFormById ({ commit }, { code, data }) {
    const result = await API.updateFormById({ code, data })
    commit('setFormData', result.status === 'SUCCESS' ? result.data : {})
    return result
  },
  async uploadSignatureImage (_, { code, data }) {
    const result = await API.uploadSignatureImage({ code, data })
    return result
  }
}

export const mutations = {
  setFormData (state, data) {
    state.formData = data
  }
}

export const getters = {
  formDataItem: state => state.formData
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
