import { clone } from 'lodash-es'
import { DateTime, Info, Settings } from 'luxon'

const timezoneDefault = 'Asia/Taipei'
const allowTimeZoneList = [
  { zoneName: 'Asia/Taipei', zoneOffset: 8 },
  { zoneName: 'Asia/Tokyo', zoneOffset: 9 }
]

function setTimeLocale (locale = 'zh-TW') {
  Settings.defaultLocale = locale
}

function getTimeZoneAndLocale () {
  return { zone: timezoneDefault, defaultLocale: 'zh-TW' }
}

function getWeekdayNameList ({ type = 'short', optionLocale = '' }) {
  // NOTE: 強制調整成週日為起始
  const { zone } = getTimeZoneAndLocale()
  const weekdayNames = Info.weekdays(type, { locale: optionLocale !== '' ? optionLocale : DateTime.now().setZone(zone).locale })
  const sundayWeekName = weekdayNames[weekdayNames.length - 1]
  const newWeekNamesArr = [sundayWeekName].concat(clone(weekdayNames).splice(0, weekdayNames.length - 1))
  return newWeekNamesArr
}

function renderUnixTimeFormat ({ timestamp = null, format = 'yyyy/LL/dd' }) {
  if (!timestamp) return
  const { zone, defaultLocale } = getTimeZoneAndLocale()
  return DateTime.fromSeconds(timestamp).setZone(zone).setLocale(defaultLocale).toFormat(format)
}

function renderUnixTimeToDate ({ timestamp = null }) {
  // NOTE: 只有 mixpanel 值有用到
  if (!timestamp) return
  return convertJSDateFromUnixTime({ unixTime: timestamp })
}

function renderStringTimeToDate ({ text = '', format = 'yyyy/LL/dd' }) {
  // 取得 HH:mm 之類的時間不能進行 offset 轉換
  if (!text) return
  const { zone } = getTimeZoneAndLocale()
  return DateTime.fromFormat(text, format).setZone(zone).toJSDate()
}

function renderJSDateFormat ({ date = null, format = 'yyyy/LL/dd' }) {
  // 暫時還沒有實際應用 (form-template 下的 time-picker 和 date-picker)
  // 先轉成 Unix Time 再進行 Format
  if (!date) return
  const unixTime = renderJSDateToUnixTime({ date })
  return renderUnixTimeFormat({ timestamp: unixTime, format })
}

function renderJSDateToUnixTime ({ date = null }) {
  if (!date) return
  const { zone } = getTimeZoneAndLocale()
  const { zoneOffset } = allowTimeZoneList.find(zoneItem => zoneItem.zoneName === zone)
  // 取得本地時間的 offset 值
  const offset = date.getTimezoneOffset()

  // 扣掉原先產生的 offset timestamp
  const utcUnixTime = date.getTime() - (offset * 60 * 1000)
  const utcDate = new Date(utcUnixTime)

  // 扣掉原先指定時區的 timestamp 數值
  const targetZoneTimestamp = utcDate.getTime() - (zoneOffset * 60) * 60 * 1000
  return Math.floor(targetZoneTimestamp / 1000)
}

function getDateTimeForFirstDayOfWeek ({ date = null }) {
  // no use
  // sunday
  if (!date) return
  const { zone } = getTimeZoneAndLocale()
  return DateTime.fromJSDate(date).setZone(zone).startOf('week').minus({ day: 1 })
}

function getDateTimeForEndDayOfWeek ({ date = null }) {
  // no use
  // saturday
  if (!date) return
  const { zone } = getTimeZoneAndLocale()
  return DateTime.fromJSDate(date).setZone(zone).endOf('week').minus({ day: 1 })
}

function convertJSDateFromUnixTime ({ unixTime = 0 }) {
  // NOTE: 處理 offset function，產出結果為 JS Date
  const { zone } = getTimeZoneAndLocale()
  const { zoneOffset } = allowTimeZoneList.find(zoneItem => zoneItem.zoneName === zone)
  // 取得本地時間的 offset 值
  const localDate = new Date(unixTime * 1000)
  const offset = localDate.getTimezoneOffset()

  // 強制還原 timestamp 的時區到 +0
  const utcUnixTime = localDate.getTime() + (offset * 60 * 1000)
  const utcDate = new Date(utcUnixTime)

  // 強制指定時區到 +8
  const targetZoneTimestamp = utcDate.getTime() + (zoneOffset * 60) * 60 * 1000

  return new Date(targetZoneTimestamp)
}

function convertDateTimeToUnixTime (dt) {
  // no use
  // NOTE: DateTime Type -> 當地 JS Date -> 加回 Offset 前的 Unix Time 結果
  return renderJSDateToUnixTime({ date: dt.toJSDate() })
}

function convertDateTimeToJSDate (dt) {
  // no use
  // NOTE: DateTime Type -> Unix Time -> 還原成 Offset 前的 JS Date 結果
  return convertJSDateFromUnixTime({ unixTime: dt.toUnixInteger() })
}

function getDateTimeByJSDate ({ date = null }) {
  // no use
  /*
    NOTE:
    需要根據使用情境判斷
    有些情況要使用當地的 JS Date 時區，在還原 Offset 的時，值才不會錯誤
  */
  if (!date) return
  return DateTime.fromJSDate(date)
}

function getDateTimeByText ({ text = '', format = 'yyyy/LL/dd' }) {
  // NOTE: 不能設定時區
  if (text === '') return
  return DateTime.fromFormat(text, format)
}

function getDateTimeByUnixTime ({ unixTime = null }) {
  if (!unixTime) return
  const { zone } = getTimeZoneAndLocale()
  return DateTime.fromSeconds(unixTime).setZone(zone)
}

function getNow ({ type = 'UnixTime' }) {
  const { zone } = getTimeZoneAndLocale()
  switch (type) {
    case 'DateTimeObject':
      return DateTime.now().setZone(zone)
    case 'UnixTime':
      return DateTime.now().setZone(zone).toUnixInteger()
    case 'Timestamp':
      return DateTime.now().setZone(zone).valueOf()
    case 'String':
      return DateTime.now().setZone(zone).toString()
    case 'JSDate':
      // no use
      // NOTE: 取得台灣時間的 Unix Time 進行 Offset 後，結果當地時區 + 台灣時間顯示
      return convertJSDateFromUnixTime({ unixTime: DateTime.now().setZone(zone).toUnixInteger() })
  }
}

export {
  setTimeLocale,
  getTimeZoneAndLocale,
  renderUnixTimeFormat,
  renderUnixTimeToDate,
  renderJSDateFormat,
  renderJSDateToUnixTime,
  renderStringTimeToDate,
  getDateTimeForFirstDayOfWeek,
  getDateTimeForEndDayOfWeek,
  getWeekdayNameList,
  getNow,
  convertDateTimeToUnixTime,
  convertDateTimeToJSDate,
  getDateTimeByJSDate,
  getDateTimeByText,
  getDateTimeByUnixTime
}
